import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { ProjectReducer } from '../features/project'
import { DrawingReducer } from '../features/drawing'
import { PropertiesReducer } from '../features/properties'
import { DrawingPageReducer } from '../features/drawingPage'
import { LoginReducer } from '../features/login'

const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    projects: ProjectReducer,
    drawings: DrawingReducer,
    properties: PropertiesReducer,
    drawingPage: DrawingPageReducer,
    login: LoginReducer,
  })

export default rootReducer
