import React from 'react'
import { useSelector, connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import PropertiesForm from '../components/PropertiesForm'
import { getProject } from '../features/project/selectors'
import { selectors as propertiesSelectors } from '../features/properties'
import { onDeleteProject } from '../features/project/actions'

const ProjectProperties: React.FC = (props: any) => {
  const { id } = useParams()
  const project = useSelector(getProject)(id)
  const properties = useSelector(propertiesSelectors.getProperties)(
    project.propertiesId
  )
  const history = useHistory()
  const { onDeleteProject } = props
  const onDelete = () => {
    onDeleteProject(project).then(() => {
      window.alert(`Projeto "${project.title}" excluído`)
      history.push('/projects')
    })
  }

  return (
    <div className="page project properties">
      <PropertiesForm properties={properties} onDelete={onDelete} />
    </div>
  )
}

export default connect(null, { onDeleteProject })(ProjectProperties)
