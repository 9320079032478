import Block, { Path } from '../../../features/drawing/Block'
import { DrawingLine } from '../../../features/drawing/Line'

/*

        * b0
        | \
 a1 *---*b1 * c1
        | /
        * b2

*/
const calculateElectric = (block: Block, line: DrawingLine): Path[] => {
  const { pos } = block
  const electric = block
  const angle = line.angle + Math.PI / 2 + Math.PI * electric.rot
  const a1 = {
    x: line.p1.x + pos * Math.cos(line.angle),
    y: line.p1.y + pos * Math.sin(line.angle),
  }
  const b1 = {
    x: a1.x + 0.08 * Math.cos(angle),
    y: a1.y + 0.08 * Math.sin(angle),
  }
  const b0 = {
    x: b1.x + 0.04 * Math.cos(angle - Math.PI / 2),
    y: b1.y + 0.04 * Math.sin(angle - Math.PI / 2),
  }
  const b2 = {
    x: b1.x + 0.04 * Math.cos(angle + Math.PI / 2),
    y: b1.y + 0.04 * Math.sin(angle + Math.PI / 2),
  }
  const c1 = {
    x: b1.x + 0.08 * Math.cos(angle),
    y: b1.y + 0.08 * Math.sin(angle),
  }
  const electricPath = `M ${a1.x} ${a1.y} L ${b1.x} ${b1.y} L ${b2.x} ${b2.y} L ${c1.x} ${c1.y} L ${b0.x} ${b0.y} L ${b1.x}, ${b1.y}`

  return [{ d: electricPath, className: 'block-path', strokeDasharray: '1 0' }]
}

export const electricRotOptions = [0, 1]

// TODO: limitar alterar "largura"

export default calculateElectric

// export interface WindowProps {
//   block: Block
//   line: DrawingLine
//   onBlockClick?: (block: Block) => void
//   selected?: boolean
//   onDimensionClick?: (dimensionIndex: number) => void
//   selectedDimensionIndex?: number
// }
// const Window: React.FC<WindowProps> = ({
//   block,
//   line,
//   onBlockClick = (b: Block) => {},
//   selected = false,
//   onDimensionClick = (dimensionIndex: number) => {},
//   selectedDimensionIndex,
// }: WindowProps) => {
//   const paths = calculateBlock(block, line)
//   const dim = getGenericDimensions(block, line, selectedDimensionIndex)
//   const selectedClassName = selected ? 'selected ' : ''
//   const onClick = (event: any) => onBlockClick(block)
//   const onDimClick = (index: number) => () => onDimensionClick(index)
//   return (
//     <>
//       {paths.map((path: Path) => (
//         <path
//           d={path.d}
//           className={`${path.className} ${selectedClassName}`}
//           strokeDasharray={path.strokeDasharray}
//           onClick={onClick}
//         />
//       ))}
//       {selected && (
//         <>
//           <Dimension dimension={dim[0]} onClick={onDimClick(0)} />
//           <Dimension dimension={dim[1]} onClick={onDimClick(1)} />
//           <Dimension dimension={dim[2]} onClick={onDimClick(2)} />
//         </>
//       )}
//     </>
//   )
// }

// export default Window
