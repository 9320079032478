import Line from './Line'
import Block from './Block'

export const lastLine = (drawing: Drawing) => {
  return drawing?.lines && drawing.lines.length > 0
    ? drawing.lines.filter((l: Line) => !l.deleted).slice(-1)[0]
    : undefined
}

export default interface Drawing {
  id: any
  propertiesId: any
  lines: Line[]
  blocks?: Block[]
  title?: string
}
