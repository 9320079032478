import gsap, { Power1 } from 'gsap'

const getShowPropertiesTimeline = (node: any, delay: number) => {
  const timeline = gsap.timeline({ paused: true })
  if (!node) {
    return timeline
  }
  const propertiesForm = document.querySelector('.page.properties')

  timeline.from(propertiesForm, 0.3, {
    yPercent: 100,
    delay: 0,
    ease: Power1.easeOut,
    clearProps: 'transform',
  })

  return timeline
}
const getHidePropertiesTimeline = (node: any, delay: number) => {
  const timeline = gsap.timeline({ paused: true })
  if (!node) {
    return timeline
  }
  const propertiesForm = document.querySelector('.page.properties')

  timeline.to(propertiesForm, 0.3, {
    yPercent: 100,
    delay: 0,
    ease: Power1.easeOut,
    clearProps: 'transform',
  })

  return timeline
}

const getDefaultTimeline = (node: any, delay: number) => {
  const timeline = gsap.timeline({ paused: true })
  if (!node) {
    return timeline
  }
  const floatingElements = node.querySelectorAll('.floating')
  const drawingOptions = document.querySelectorAll('.drawing-option')
  // const contentInner = node.querySelector('.content--inner')

  timeline
    .from(node, 0.3, {
      xPercent: 100,
      delay,
      ease: Power1.easeOut,
      clearProps: 'transform',
    })
    .from(floatingElements, 0.375, {
      autoAlpha: 0,
      xPercent: 25,
      ease: Power1.easeOut,
      clearProps: 'transform',
    })

  drawingOptions?.forEach((element: any) => {
    timeline.from(
      element,
      0.375,
      {
        autoAlpha: 0,
        y: 15,
        ease: Power1.easeOut,
        clearProps: 'transform',
      },
      '<0.1'
    )
  })
  return timeline
}

const getHomeTimeline = (node: any, delay: number) => {
  const timeline = gsap.timeline({ paused: true })
  const floatingElements = node.querySelectorAll('.floating')
  timeline
    .from(node, 0.3, {
      xPercent: -100,
      delay,
      ease: Power1.easeOut,
      clearProps: 'transform',
    })
    .from(floatingElements, 0.375, {
      autoAlpha: 0,
      x: -25,
      ease: Power1.easeOut,
      clearProps: 'transform',
    })

  return timeline
}

const loadPromise = new Promise(resolve => {
  window.addEventListener('load', resolve)
})

export const play = (
  pathname: string,
  node: any,
  appears: any,
  from?: string
) => {
  const delay = appears ? 0 : 0.2
  let timeline: any

  if (pathname === '/') {
    timeline = getHomeTimeline(node, delay)
  } else if (pathname.endsWith('/properties')) {
    timeline = getShowPropertiesTimeline(node, delay)
  } else if (from?.endsWith('/properties')) {
    timeline = getHidePropertiesTimeline(node, delay)
  } else if (
    from &&
    from.length > pathname.length &&
    from.indexOf(pathname) >= 0
  ) {
    timeline = getHomeTimeline(node, delay)
  } else {
    timeline = getDefaultTimeline(node, delay)
  }

  loadPromise.then(() => requestAnimationFrame(() => timeline.play()))
}

export const exit = (node: any) => {
  const timeline = gsap.timeline({ paused: true })

  timeline.to(node, 0.15, { autoAlpha: 0, ease: Power1.easeOut })
  timeline.play()
}
