import * as ActionTypes from './actionTypes'
import { Login } from './types'

const initialState: Login = {
  loggedIn: false,
  user: {
    username: '',
    nicename: '',
    avatar: '',
  },
  error: undefined,
}

const loginReducer = (state = initialState, action: any): Login => {
  switch (action.type) {
    case ActionTypes.LOGIN:
    case ActionTypes.SILENT_LOGIN:
      return {
        ...state,
        loggedIn: true,
        error: undefined,
        user: action.payload,
      }
    case ActionTypes.LOGIN_ERROR:
    case ActionTypes.SILENT_LOGOUT:
      return { ...state, error: action.payload }
    case ActionTypes.LOGOUT:
      return { ...initialState, error: action.payload }
    default:
      return state
  }
}

export default loginReducer
