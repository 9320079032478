export const addVisibilityChangeListener = (listener: Function) => {
  let hidden: string
  let visibilityChange: string
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof (document as any).msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  } else {
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  }

  window.document.addEventListener(visibilityChange, () => {
    if (!(document as any)[hidden]) {
      listener()
    }
  })
}
