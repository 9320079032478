import LineType, { CONTINUOUS } from './LineType'
import Point from './Point'

interface Line {
  readonly p1?: Point
  readonly length: number
  readonly angle: number
  readonly type: LineType
  readonly id?: any
  readonly parent?: any
  readonly deleted?: boolean
}

export interface DrawingLine {
  readonly id: any
  readonly p1: Point
  readonly p2: Point
  readonly type: LineType
  readonly length: number
  readonly angle: number
  readonly deleted?: boolean
}

export const newLine = (length = 0, angle = 0, type = CONTINUOUS) => {
  return {
    length,
    angle,
    type,
    // id: undefined,
    // parent: undefined,
    // deleted: undefined,
  }
}

export const withParent = (childLine: Line, parentLine?: Line): Line => {
  return {
    ...childLine,
    parent: parentLine ? parentLine.id : undefined,
  }
}

export const deleted = (line: Line): Line => {
  return {
    ...line,
    deleted: true,
  }
}

export const updateLine = (
  line: Line,
  newLength: number,
  newAngle: number,
  newType: LineType
): Line => {
  return {
    ...line,
    length: newLength,
    angle: newAngle,
    type: newType,
  }
}

export const equals = (line1: Line, line2: Line): boolean => {
  return line1.id === line2.id
}

const calculateP2 = (p1: Point, length: number, angle: number): Point => {
  return new Point(
    p1.x + Math.cos(angle) * length,
    p1.y + Math.sin(angle) * length
  )
}
export const calculateLine = (fromPoint: Point, line: Line): DrawingLine => {
  return {
    id: line.id,
    p1: fromPoint,
    p2: line.deleted
      ? fromPoint
      : calculateP2(fromPoint, line.length, line.angle),
    type: line.type,
    length: line.length,
    angle: line.angle,
    deleted: line.deleted,
  }
}
const findParentP2 = (line: Line, lines: DrawingLine[]): Point => {
  const parent = lines.filter(l => l.id === line.parent)[0]
  return parent.p2
}

export const calculateLines = (lines: Line[]): DrawingLine[] => {
  let orphanedLines = lines.filter(line => !line.parent)
  if (orphanedLines.length === 0) {
    let lineWithMinId
    let minId = Number.MAX_SAFE_INTEGER
    lines.forEach(line => {
      if (line.id < minId) {
        lineWithMinId = line
        minId = line.id
      }
    })
    if (lineWithMinId) {
      orphanedLines = [lineWithMinId]
    } else {
      // orphanedLines stays empty
    }
    // if (lines.length > 0) {
    //   orphanedLines = lines.reduce((lineWithMinId, line) => lineWithMinId.id < line.id ? lineWithMinId : line, lines[0])
    // }
  }
  const p1 = new Point()
  let restOfLines = lines
  const calculatedLines = orphanedLines.map(orphan => calculateLine(p1, orphan))
  const calculatedIds = calculatedLines.map(line => line.id)

  let counter = lines.length
  while (restOfLines.length > 0 && counter > 0) {
    counter -= 1

    restOfLines = restOfLines.filter(line => !calculatedIds.includes(line.id))
    const childLines = restOfLines
      .filter(line => calculatedIds.includes(line.parent))
      .map(line => calculateLine(findParentP2(line, calculatedLines), line))
    calculatedIds.push(...childLines.map(line => line.id))
    calculatedLines.push(...childLines)
  }
  return calculatedLines.filter(l => !l.deleted)
}

export default Line
