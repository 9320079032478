import React from 'react'
import BlockType from '../../../features/drawing/BlockType'
import Block, { Path, DrawingDimension } from '../../../features/drawing/Block'
import calculateDoor, { doorRotOptions } from './door'
import calculateWindow, { windowRotOptions } from './window'
import calculateElectric, { electricRotOptions } from './electric'
import { DrawingLine } from '../../../features/drawing/Line'
import Dimension, { getGenericDimensions } from './Dimension'

export const getBlockPositionOptions = (block: Block): number[] => {
  switch (block.type) {
    case BlockType.DOOR:
      return doorRotOptions
    case BlockType.WINDOW:
      return windowRotOptions
    case BlockType.ELECTRIC:
      return electricRotOptions
    default:
      return [0]
  }
}

const calculateBlock = (block: Block, line: DrawingLine): Path[] => {
  switch (block.type) {
    case BlockType.DOOR:
      return calculateDoor(block, line)
    case BlockType.WINDOW:
      return calculateWindow(block, line)
    case BlockType.ELECTRIC:
      return calculateElectric(block, line)
    default:
      return []
  }
}

interface BlockProps {
  block: Block
  line: DrawingLine
  onBlockClick?: (block: Block) => void
  selected?: boolean
  onDimensionClick?: (dimension: DrawingDimension) => void
  selectedDimension?: DrawingDimension
  fadeOut?: boolean
}
const GenericBlock: React.FC<BlockProps> = ({
  block,
  line,
  onBlockClick = (b: Block) => {},
  selected = false,
  fadeOut = false,
  onDimensionClick = (dimension: DrawingDimension) => {},
  selectedDimension,
}: BlockProps) => {
  const paths = calculateBlock(block, line)
  const dim = getGenericDimensions(block, line, selectedDimension)
  const selectedClassName = selected ? 'selected' : ''
  const fadeOutClassName = fadeOut ? 'fade-out' : ''
  const onClick = (event: any) => onBlockClick(block)
  const onDimClick = (index: number) => () => onDimensionClick(dim[index])
  return (
    <>
      {paths.map((path: Path) => (
        <path
          key={path.d}
          d={path.d}
          className={`${path.className} ${selectedClassName} ${fadeOutClassName}`}
          strokeDasharray={path.strokeDasharray}
          onClick={onClick}
        />
      ))}
      {selected && (
        <>
          <Dimension dimension={dim[0]} onClick={onDimClick(0)} />
          <Dimension dimension={dim[1]} onClick={onDimClick(1)} />
          <Dimension dimension={dim[2]} onClick={onDimClick(2)} />
        </>
      )}
    </>
  )
}

export default GenericBlock
