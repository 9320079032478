import Block, { Path } from '../../../features/drawing/Block'
import { DrawingLine } from '../../../features/drawing/Line'
import round from '../../../features/drawing/roundNumber'

const calculateDoor = (block: Block, line: DrawingLine): Path[] => {
  const { pos, width, rot } = block
  const p1 = {
    x: round(line.p1.x + pos * Math.cos(line.angle)),
    y: round(line.p1.y + pos * Math.sin(line.angle)),
  }
  const p12 = {
    x: round(p1.x + (width * Math.cos(line.angle)) / 20.0),
    y: round(p1.y + (width * Math.sin(line.angle)) / 20.0),
  }
  const p2 = {
    x: round(p1.x + width * Math.cos(line.angle)),
    y: round(p1.y + width * Math.sin(line.angle)),
  }
  const p22 = {
    x: round(p1.x + width * (19.0 / 20.0) * Math.cos(line.angle)),
    y: round(p1.y + width * (19.0 / 20.0) * Math.sin(line.angle)),
  }
  const p3 = {
    x: round(p2.x + width * Math.cos(line.angle + Math.PI / 2)),
    y: round(p2.y + width * Math.sin(line.angle + Math.PI / 2)),
  }
  const p32 = {
    x: round(p3.x + (width * Math.cos(line.angle + Math.PI)) / 20.0),
    y: round(p3.y + (width * Math.sin(line.angle + Math.PI)) / 20.0),
  }
  const p4 = {
    x: round(p3.x + width * Math.cos(line.angle + Math.PI)),
    y: round(p3.y + width * Math.sin(line.angle + Math.PI)),
  }
  const p42 = {
    x: round(p3.x + width * (19.0 / 20.0) * Math.cos(line.angle + Math.PI)),
    y: round(p3.y + width * (19.0 / 20.0) * Math.sin(line.angle + Math.PI)),
  }
  const p5 = {
    x: round(p2.x + width * Math.cos(line.angle - Math.PI / 2)),
    y: round(p2.y + width * Math.sin(line.angle - Math.PI / 2)),
  }
  const p52 = {
    x: round(p5.x + (width * Math.cos(line.angle - Math.PI)) / 20.0),
    y: round(p5.y + (width * Math.sin(line.angle - Math.PI)) / 20.0),
  }
  const p6 = {
    x: round(p5.x + width * Math.cos(line.angle - Math.PI)),
    y: round(p5.y + width * Math.sin(line.angle - Math.PI)),
  }
  const p62 = {
    x: round(p5.x + width * (19.0 / 20.0) * Math.cos(line.angle - Math.PI)),
    y: round(p5.y + width * (19.0 / 20.0) * Math.sin(line.angle - Math.PI)),
  }
  let doorPath = ''
  switch (rot) {
    case 0:
      doorPath = `M ${p2.x} ${p2.y} L ${p3.x} ${p3.y} L ${p32.x} ${p32.y} L ${p22.x} ${p22.y} L ${p2.x} ${p2.y}`
      break
    case 1:
      doorPath = `M ${p62.x} ${p62.y} L ${p6.x} ${p6.y} L ${p1.x} ${p1.y} L ${p12.x} ${p12.y} L ${p62.x} ${p62.y}`
      break
    case 2:
      doorPath = `M ${p42.x} ${p42.y} L ${p4.x} ${p4.y} L ${p1.x} ${p1.y} L ${p12.x} ${p12.y} L ${p42.x} ${p42.y}`
      break
    case 3:
    default:
      doorPath = `M ${p2.x} ${p2.y} L ${p5.x} ${p5.y} L ${p52.x} ${p52.y} L ${p22.x} ${p22.y} L ${p2.x} ${p2.y}`
  }
  let arcPath = ''
  switch (rot) {
    case 0:
      arcPath = `M ${p32.x} ${p32.y} Q ${p4.x} ${p4.y} , ${p1.x} ${p1.y} L ${p2.x} ${p2.y}`
      break
    case 1:
      arcPath = `M ${p1.x} ${p1.y} L ${p2.x} ${p2.y} Q ${p5.x} ${p5.y} , ${p62.x} ${p62.y}`
      break
    case 2:
      arcPath = `M ${p1.x} ${p1.y} L ${p2.x} ${p2.y} Q ${p3.x} ${p3.y} , ${p42.x} ${p42.y}`
      break
    case 3:
    default:
      arcPath = `M ${p52.x} ${p52.y} Q ${p6.x} ${p6.y} , ${p1.x} ${p1.y} L ${p2.x} ${p2.y}`
  }
  const gapPath = `M ${p1.x} ${p1.y} L ${p2.x} ${p2.y}`
  return [
    {
      d: arcPath,
      className: 'block-alternate-path',
      strokeDasharray: `0.15, 0.08`,
    },
    { d: gapPath, className: 'block-gap', strokeDasharray: '1 0' },
    { d: doorPath, className: 'block-path', strokeDasharray: '1 0' },
  ]
}

export const doorRotOptions = [0, 1, 2, 3]

export default calculateDoor
// export interface DoorProps {
//   block: Block
//   line: DrawingLine
//   onBlockClick?: (block: Block) => void
//   selected?: boolean
//   onDimensionClick?: (dimensionIndex: number) => void
//   selectedDimensionIndex?: number
// }
// const Door: React.FC<DoorProps> = ({
//   block,
//   line,
//   onBlockClick = (b: Block) => {},
//   selected = false,
//   onDimensionClick = (dimensionIndex: number) => {},
//   selectedDimensionIndex,
// }: DoorProps) => {
//   const paths = calculateBlock(block, line)
//   const dim = getGenericDimensions(block, line, selectedDimensionIndex)
//   const selectedClassName = selected ? 'selected ' : ''
//   const onClick = (event: any) => onBlockClick(block)
//   const onDimClick = (index: number) => () => onDimensionClick(index)
//   return (
//     <>
//       {paths.map((path: Path) => (
//         <path
//           d={path.d}
//           className={`${path.className} ${selectedClassName}`}
//           strokeDasharray={path.strokeDasharray}
//           onClick={onClick}
//         />
//       ))}
//       {selected && (
//         <>
//           <Dimension dimension={dim[0]} onClick={onDimClick(0)} />
//           <Dimension dimension={dim[1]} onClick={onDimClick(1)} />
//           <Dimension dimension={dim[2]} onClick={onDimClick(2)} />
//         </>
//       )}
//     </>
//   )
// }

// export default Door
