export const login = (username: string, password: string) =>
  fetch('/ajax-login.php', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  }).then(response => response.json())

export const loggedUser = () =>
  fetch('/logged-user.php').then(response => response.json())
