import React from 'react'
import Block, { DrawingDimension } from '../../../features/drawing/Block'
import { DrawingLine } from '../../../features/drawing/Line'
import round from '../../../features/drawing/roundNumber'

// export const WIDTH = 'WIDTH';
// export const POS = 'POS';
// export const OPPOSITE = 'OPPOSITE';

// export const getBlockLengths = (block, line, selectedDimension) => {
//     switch (selectedDimension) {
//         case OPPOSITE:
//             return round(line.length - block.width - block.pos);
//         case POS:
//             return round(block.pos);
//         case WIDTH:
//         default:
//             return round(block.width);
//     }
// }

export const getGenericDimensions = (
  block: Block,
  line: DrawingLine,
  selectedDimension?: DrawingDimension
): DrawingDimension[] => {
  const blockPos = block.pos
  const blockWidth = block.width
  const perpAngle =
    line.angle - (block.rot % 2 === 0 ? Math.PI / 2 : -Math.PI / 2)
  const blockP1 = {
    x: line.p1.x + blockPos * Math.cos(line.angle),
    y: line.p1.y + blockPos * Math.sin(line.angle),
  }
  const blockP2 = {
    x: line.p1.x + (blockPos + blockWidth) * Math.cos(line.angle),
    y: line.p1.y + (blockPos + blockWidth) * Math.sin(line.angle),
  }
  const dimDist = {
    block,
    index: 0,
    length: round(block.pos),
    p1: line.p1,
    p2: blockP1,
    angle: line.angle,
    perpAngle,
    selected: selectedDimension ? selectedDimension.index === 0 : true,
  }
  const dimBlock = {
    block,
    index: 1,
    length: round(block.width),
    p1: blockP1,
    p2: blockP2,
    angle: line.angle,
    perpAngle,
    selected: selectedDimension?.index === 1,
  }
  const dimOppositeDist = {
    block,
    index: 2,
    length: round(line.length - block.width - block.pos),
    p1: blockP2,
    p2: line.p2,
    angle: line.angle,
    perpAngle,
    selected: selectedDimension?.index === 2,
  }
  return [dimDist, dimBlock, dimOppositeDist]
}

interface DimensionProps {
  dimension: DrawingDimension
  onClick?: () => void
}
const Dimension: React.FC<DimensionProps> = ({
  dimension,
  onClick = () => {},
}: DimensionProps) => {
  const { length, angle, perpAngle, selected } = dimension
  if (length < 0.01) {
    return <></>
  }
  const p1 = {
    x: dimension.p1.x + 0.08 * Math.cos(perpAngle),
    y: dimension.p1.y + 0.08 * Math.sin(perpAngle),
  }
  const p2 = {
    x: dimension.p2.x + 0.08 * Math.cos(perpAngle),
    y: dimension.p2.y + 0.08 * Math.sin(perpAngle),
  }
  const p12 = {
    x: dimension.p1.x + 0.3 * Math.cos(perpAngle),
    y: dimension.p1.y + 0.3 * Math.sin(perpAngle),
  }
  const p22 = {
    x: dimension.p2.x + 0.3 * Math.cos(perpAngle),
    y: dimension.p2.y + 0.3 * Math.sin(perpAngle),
  }
  const p13 = {
    x: dimension.p1.x + 0.24 * Math.cos(perpAngle),
    y: dimension.p1.y + 0.24 * Math.sin(perpAngle),
  }
  const p23 = {
    x: dimension.p2.x + 0.24 * Math.cos(perpAngle),
    y: dimension.p2.y + 0.24 * Math.sin(perpAngle),
  }
  const rel1 = {
    dx: 0.06 * Math.cos(perpAngle + Math.PI / 4),
    dy: 0.06 * Math.sin(perpAngle + Math.PI / 4),
  }
  const rel2 = {
    dx: 0.12 * Math.cos(perpAngle - (Math.PI * 3) / 4),
    dy: 0.12 * Math.sin(perpAngle - (Math.PI * 3) / 4),
  }
  const dimPath = `M ${p1.x}, ${p1.y} L ${p12.x}, ${p12.y} M ${p2.x}, ${p2.y} L ${p22.x}, ${p22.y} M ${p13.x}, ${p13.y} L ${p23.x}, ${p23.y} m ${rel1.dx}, ${rel1.dy} l ${rel2.dx}, ${rel2.dy} M ${p13.x}, ${p13.y} m ${rel1.dx}, ${rel1.dy} l ${rel2.dx}, ${rel2.dy}`
  let textAngle = (angle / Math.PI) * 180
  if (textAngle > 180) {
    textAngle = 180 - textAngle
  }
  const textDist = perpAngle < angle ? 0.02 : -0.02
  const textPos = {
    x:
      (p13.x + p23.x) / 2 -
      0.1 * Math.cos(angle) +
      textDist * Math.cos(perpAngle),
    y:
      (p13.y + p23.y) / 2 -
      0.1 * Math.sin(angle) +
      textDist * Math.sin(perpAngle),
  }
  const onDimClick = (event: any) => onClick()
  const selectedClassName = selected ? 'selected' : ''
  return (
    <>
      <path
        className="dimension-text"
        d={`M ${p1.x}, ${p1.y} L ${p12.x}, ${p12.y} L ${p22.x}, ${p22.y} L ${p2.x}, ${p2.y} Z`}
        onClick={onDimClick}
      />
      <text
        className={selectedClassName}
        x={textPos.x}
        y={textPos.y}
        transform={`rotate(${textAngle}, ${textPos.x}, ${textPos.y})`}
        fontSize="0.16"
        onClick={onDimClick}
      >
        {length}
      </text>
      <path d={dimPath} className="dimension-background" onClick={onDimClick} />
      <path
        d={dimPath}
        className={`dimension ${selectedClassName}`}
        onClick={onDimClick}
      />
    </>
  )
}

export default Dimension
