/*
 * Useful Links:
 *
 * AutoCAD 2012 DXF Reference:
 * http://images.autodesk.com/adsk/files/autocad_2012_pdf_dxf-reference_enu.pdf
 *
 * Writing a DXF File:
 * https://www.autodesk.com/techpubs/autocad/acad2000/dxf/writing_a_dxf_file_dxf_aa.htm
 *
 *
 * Quote from Writing a DXF File:
 *
 * "AutoCAD lets you omit many items in a DXF file and still obtain a usable drawing.
 *
 * * The entire HEADER section can be omitted if you don't set header variables.
 *
 * * Any of the tables in the TABLES section can be omitted if you don't need to
 *   make entries, and the entire TABLES section can be dropped if nothing in it
 *   is required.
 * * If you define any linetypes in the LTYPE table, this table must appear
 *   before the LAYER table.
 *
 * * If no block definitions are used in the drawing, the BLOCKS section can be
 *   omitted.
 * * If present, the BLOCKS section must appear before the ENTITIES section.
 *
 * * Within the ENTITIES section, you can reference layer names even though you
 *   haven't defined them in the LAYER table. Such layers are automatically
 *   created with color 7 and the CONTINUOUS linetype.
 * * The EOF item must be present at the end of file"
 *
 */
import Point from '../drawing/Point'
import { TABLES } from './dxfTables'
import { getDoorLines } from './DxfDoor'
import { getWindowLines } from './DxfWindow'
import { getElectricLines } from './DxfElectric'
import { DrawingLine } from '../drawing/Line'
import Block from '../drawing/Block'
import { BlockType } from '../drawing'
import DxfLine from './DxfLine'

const UNITS = {
  Unitless: 0,
  Inches: 1,
  Feet: 2,
  Miles: 3,
  Millimeters: 4,
  Centimeters: 5,
  Meters: 6,
  Kilometers: 7,
  Microinches: 8,
  Mils: 9,
  Yards: 10,
  Angstroms: 11,
  Nanometers: 12,
  Microns: 13,
  Decimeters: 14,
  Decameters: 15,
  Hectometers: 16,
  Gigameters: 17,
  'Astronomical units': 18,
  'Light years': 19,
  Parsecs: 20,
}

const getBlockFunction = (block: Block) => {
  switch (block.type) {
    case BlockType.DOOR:
      return getDoorLines
    case BlockType.WINDOW:
      return getWindowLines
    case BlockType.ELECTRIC:
      return getElectricLines
    default:
      return () => []
  }
}

class DxfBuilder {
  file: string[] = []

  add(line: string) {
    this.file.push(line)
  }

  section(name: string) {
    this.add('  0')
    this.add('SECTION')
    this.add('  2')
    this.add(name)
  }

  header() {
    this.section('HEADER')
  }

  blocks() {
    this.section('BLOCKS')
  }

  entities() {
    this.section('ENTITIES')
  }

  tables() {
    this.section('TABLES')
  }

  endSec() {
    this.add('  0')
    this.add('ENDSEC')
  }

  EOF() {
    this.add(' 0')
    this.add('EOF')
  }

  extMin(x: string, y: string, z: string) {
    this.add('  9')
    this.add('$EXTMIN')
    this.add('  10')
    this.add(x)
    this.add('  20')
    this.add(y)
    this.add('  30')
    this.add(z)
  }

  limMin(x: string, y: string) {
    this.add('  9')
    this.add('$LIMMIN')
    this.add('  10')
    this.add(x)
    this.add('  20')
    this.add(y)
  }

  extMax(x: string, y: string, z: string) {
    this.add('  9')
    this.add('$EXTMAX')
    this.add('  10')
    this.add(x)
    this.add('  20')
    this.add(y)
    this.add('  30')
    this.add(z)
  }

  limMax(x: string, y: string) {
    this.add('  9')
    this.add('$LIMMAX')
    this.add('  10')
    this.add(x)
    this.add('  20')
    this.add(y)
  }

  units(unit: any) {
    const number = (UNITS as any)[unit]
    this.add('  9')
    this.add('$INSUNITS')
    this.add('  70')
    this.add(`${number}`)
  }

  projectName(name: string) {
    this.add('  9')
    this.add('$PROJECTNAME')
    this.add('  1')
    this.add(name)
  }

  timeDateCreate(date: string) {
    this.add('  9')
    this.add('$TDCREATE')
    this.add('  40')
    this.add(this.julianDate(date))
  }

  ltScale() {
    this.add('  9')
    this.add('$LTSCALE')
    this.add('  40')
    this.add('0.005')
  }

  julianDate(date: string) {
    // TODO!
    return '0'
  }

  lineEntity(index: number, p1: Point, p2: Point, layer: string) {
    //    this.add(" -1"); // APP: Entity name
    //    this.add("line"+index);
    this.add('  0') // Entity type
    this.add('LINE')
    this.add('  5') // Handle
    this.add(`${index}`)
    //    this.add("330"); // Soft-pointer ID/handle to owner BLOCK_RECORD object
    //    this.add("");
    this.add('100') // Subclass masker (AcDbEntity)
    this.add('AcDbEntity')
    //    this.add("410"); // APP: layout tab name
    //    this.add("");
    this.add('  8') // Layer name
    this.add(layer)
    //    this.add("370"); // Lineweight enum value. Stored and moved around as a 16-bit integer
    //    this.add("");
    this.add('100') // Subclass marker (AcDbLine)
    this.add('AcDbLine')
    this.add(' 39') // Thickness
    this.add('1')
    this.add(' 10') // Startpoint X
    this.add(`${p1.x}`)
    this.add(' 20') // Startpoint Y
    this.add(`${p1.y}`)
    this.add(' 11') // Startpoint X
    this.add(`${p2.x}`)
    this.add(' 21') // Startpoint Y
    this.add(`${p2.y}`)
  }

  linesToDxf(
    projectName: string,
    unit: string,
    lines: DrawingLine[],
    blocks?: Block[]
  ) {
    this.header()
    this.projectName(projectName)
    this.units(unit)
    //    timeDateCreate(window.now()); // not working yet
    //    limMin(); // not really needed
    //    limMax(); // not really needed
    this.ltScale()
    this.endSec()

    this.add(TABLES)

    this.entities()
    let i = 0
    lines.forEach(line => {
      // Javascript has rounding problems...
      // and AutoCAD's Y axis points up!
      const p1 = new Point(line.p1.x, -line.p1.y)
      const p2 = new Point(line.p2.x, -line.p2.y)
      this.lineEntity(i, p1, p2, line.type.name)
      i += 1
    })
    blocks?.forEach((block: Block) => {
      const line = lines.filter((l: DrawingLine) => l.id === block.lineId)[0]
      getBlockFunction(block)(block, line).forEach((dxfLine: DxfLine) => {
        const p1 = new Point(dxfLine.p1.x, -dxfLine.p1.y)
        const p2 = new Point(dxfLine.p2.x, -dxfLine.p2.y)
        this.lineEntity(i, p1, p2, block.type)
        i += 1
      })
    })
    this.endSec()
    this.EOF()
  }

  getFile(): string {
    return this.file.join('\n')
  }
}

export default DxfBuilder
