import { createStore, applyMiddleware, compose } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import localStorageMiddleware from './localStorageMiddleware'
import rootReducer from './rootReducer'

export const history = createBrowserHistory()

const configureStoreDev = (preloadedState: any) => {
  const store = createStore(
    rootReducer(history),
    preloadedState,
    compose(
      applyMiddleware(thunk, routerMiddleware(history), localStorageMiddleware),
      devToolsEnhancer({})
    )
  )

  // if ((module as any).hot) {
  //   (module as any).hot.accept('../features', () =>
  //     store.replaceReducer(rootReducer)
  //   )
  // }

  return store
}

export default configureStoreDev
