import { Dispatch } from 'react'
import { getGlobalIds } from '../ids'
import {
  NEW_DRAWING,
  DELETE_DRAWING,
  LINE_FORM_OK,
  LINE_FORM_DELETE,
  NEW_BLOCK,
  BLOCK_SAVE,
  BLOCK_DELETE,
  UNDELETE_DRAWING,
} from './actionTypes'
import Project from '../project/Project'
import Drawing from './Drawing'
import Line from './Line'
import LineType from './LineType'
import Block from './Block'
import BlockType from './BlockType'
import round from './roundNumber'

export const onNewDrawing = (project: Project): Dispatch<Promise<Drawing>> => {
  const drawingId = getGlobalIds().nextId()
  const propertiesId = getGlobalIds().nextId()
  const drawing = {
    id: drawingId,
    propertiesId,
    lines: [],
    title: 'Novo Desenho',
  }
  return (dispatch: any): Promise<Drawing> => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: NEW_DRAWING,
        payload: {
          project,
          drawing,
          properties: {
            id: propertiesId,
            title: 'Novo Desenho',
            notes: '',
          },
        },
      })
      resolve(drawing)
    })
  }
}

export const onDeleteDrawing = (
  drawing: Drawing
): Dispatch<Promise<Drawing>> => {
  return (dispatch: any): Promise<Drawing> => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DELETE_DRAWING,
        payload: { drawing },
      })
      resolve()
    })
  }
}

export const onUndeleteDrawing = (
  drawing: Drawing
): Dispatch<Promise<Drawing>> => {
  return (dispatch: any): Promise<Drawing> => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UNDELETE_DRAWING,
        payload: { drawing },
      })
      resolve(drawing)
    })
  }
}

export const onLineFormOk = (
  drawing: Drawing,
  oldLine: Line,
  length: number,
  angle: number,
  type: LineType
) => {
  return {
    type: LINE_FORM_OK,
    payload: {
      drawing,
      oldLine,
      length,
      angle,
      type,
    },
  }
}

export const onDeleteLine = (drawing: Drawing, line: Line) => {
  return {
    type: LINE_FORM_DELETE,
    payload: {
      drawing,
      line,
    },
  }
}


export const onAddDoor = (drawingId: number, line: Line) => {
  return onAddBlock(drawingId, line, BlockType.DOOR)
}

export const onAddWindow = (drawingId: number, line: Line) => {
  return onAddBlock(drawingId, line, BlockType.WINDOW)
}

export const onAddElectric = (drawingId: number, line: Line) => {
  return onAddBlock(drawingId, line, BlockType.ELECTRIC)
}

export const onAddBlock = (drawingId: number, line: Line, type: BlockType) => {
  const width =
    type === BlockType.ELECTRIC ? 0 : round(Math.min(0.8, line.length))
  const pos = round(line.length / 2 - width / 2)
  const block = {
    id: getGlobalIds().nextId(),
    lineId: line.id,
    pos,
    width,
    rot: 0,
    type,
  }
  return {
    type: NEW_BLOCK,
    payload: { drawingId, block },
  }
}

export const onSaveBlock = (drawingId: number, block: Block) => {
  return {
    type: BLOCK_SAVE,
    payload: { drawingId, block },
  }
}

export const onDeleteBlock = (drawingId: number, block: Block) => {
  return {
    type: BLOCK_DELETE,
    payload: { drawingId, block },
  }
}
