let refreshing: boolean

const alertAndReload = () => {
  if (refreshing) {
    return
  }
  window.alert('Uma nova atualização será instalada.')
  window.location.reload()
  refreshing = true
}

export const reloadOnServiceWorkerUpdate = () => {
  navigator.serviceWorker.addEventListener('controllerchange', alertAndReload)
}
