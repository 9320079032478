import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  TrashOutline,
  ArrowCircleRightOutline,
  XOutline,
  ViewGridAddOutline,
} from 'heroicons-react'
import { ReactComponent as LengthSvgIcon } from './svg/length.svg'
import { ReactComponent as AngleSvgIcon } from './svg/angle.svg'
import { ReactComponent as TypeSvgIcon } from './svg/lineType.svg'
import Line from '../../features/drawing/Line'
import Block from '../../features/drawing/Block'
import LineType, { TYPES, getByName } from '../../features/drawing/LineType'
import ToolbarMenuOption from '../ToolbarMenuOption'
import { actions as drawingActions } from '../../features/drawing'
import { actions } from '../../features/drawingPage'
import Drawing from '../../features/drawing/Drawing'

const InputLength = ({ length, handleInputChange }: any) => (
  <input
    className="input--toolbar"
    id="length"
    name="length"
    type="number"
    step="0.01"
    value={length}
    onChange={handleInputChange}
  />
)

const InputAngle = ({ angle, handleInputChange }: any) => (
  <input
    className="input--toolbar"
    id="angle"
    name="angle"
    step="1"
    pattern="[0-9]*"
    value={angle}
    onChange={handleInputChange}
  />
)

const InputType = ({ type, handleInputChange }: any) => (
  <select
    className="select--toolbar"
    id="type"
    name="type"
    value={type}
    onChange={handleInputChange}
  >
    {TYPES.map((lineType: LineType) => (
      <option key={lineType.name} value={lineType.name}>
        {lineType.name}
      </option>
    ))}
  </select>
)

export interface LineFormProps {
  line: Line
  drawing: Drawing
  onLineOk: (
    drawing: Drawing,
    line: Line,
    length: number,
    angle: number,
    type: LineType
  ) => {}
  onDeleteLine: (drawingId: any, line: Line) => {}
  onCancel: () => {}
  onAddDoor: (drawingId: any, line: Line) => {}
  onAddWindow: (drawingId: any, line: Line) => {}
  onAddElectric: (drawingId: any, line: Line) => {}
  setTempEntity: (entity: Line | Block | undefined) => {}
}
type LineFormState = {
  showField?: string
  length: string
  angle: number
  type: string
}

class LineForm extends Component<LineFormProps, LineFormState, {}> {
  constructor(props: LineFormProps) {
    super(props)
    this.state = {
      showField: 'length',
      length: props.line.length !== 0 ? String(props.line.length) : '',
      angle: Math.round((props.line.angle * 180) / Math.PI),
      type: props.line.type.name,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.onOk = this.onOk.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onAddDoor = this.onAddDoor.bind(this)
    this.onAddWindow = this.onAddWindow.bind(this)
    this.onAddElectric = this.onAddElectric.bind(this)
    this.focus = this.focus.bind(this)
  }

  componentDidMount() {
    this.focus()
  }

  componentDidUpdate() {
    this.focus()
  }

  onOk(event: any) {
    event.preventDefault()
    const { length, angle, type } = this.state
    const { onLineOk, drawing, line } = this.props
    onLineOk(
      drawing,
      line,
      length !== '' ? +length : 0,
      (angle * Math.PI) / 180.0,
      getByName(type)
    )
  }

  onDelete(event: any) {
    event.preventDefault()
    const { onDeleteLine, drawing, line } = this.props
    onDeleteLine(drawing, line)
  }

  onAddDoor(event: any) {
    event.preventDefault()
    const { onAddDoor, drawing, line } = this.props
    onAddDoor(drawing.id, line)
  }

  onAddWindow(event: any) {
    event.preventDefault()
    const { onAddWindow, drawing, line } = this.props
    onAddWindow(drawing.id, line)
  }

  onAddElectric(event: any) {
    event.preventDefault()
    const { onAddElectric, drawing, line } = this.props
    onAddElectric(drawing.id, line)
  }

  focus() {
    const { showField } = this.state
    if (showField) {
      document.getElementById(showField)?.focus()
    }
  }

  handleInputChange(event: any) {
    const { name, value } = event.target
    this.setState(
      (prevState: LineFormState) => {
        if ((prevState as any)[name] === value) {
          return {
            ...prevState,
            [name]: undefined,
          }
        }
        return {
          ...prevState,
          [name]: value,
        }
      },
      () => {
        const { line, setTempEntity } = this.props
        const { length, angle, type } = this.state
        setTempEntity({
          ...line,
          length: length !== '' ? +length : 0,
          angle: (angle * Math.PI) / 180.0,
          type: getByName(type),
        })
      }
    )
  }

  render() {
    const { showField, length, angle, type } = this.state
    const { onCancel, line } = this.props
    const dontShowFloatingToolbarIfNewLine = line.length > 0
    return (
      <>
        {dontShowFloatingToolbarIfNewLine && (
          <div className="block-toolbar floating-toolbar second-level">
            <button type="button" className="floating" onClick={this.onAddDoor}>
              <ViewGridAddOutline /> Porta
            </button>
            <button
              type="button"
              className="floating"
              onClick={this.onAddWindow}
            >
              <ViewGridAddOutline /> Janela
            </button>
            <button
              type="button"
              className="floating"
              onClick={this.onAddElectric}
            >
              <ViewGridAddOutline /> Elétrico
            </button>
          </div>
        )}
        <form className={`${showField} line-form`} onSubmit={this.onOk}>
          <div className="option length">
            <ToolbarMenuOption
              label="Distância"
              onClick={() => {
                this.handleInputChange({
                  target: { name: 'showField', value: 'length' },
                })
              }}
              value={`${length}m`}
              icon={<LengthSvgIcon />}
            />
            <InputLength
              length={length}
              handleInputChange={this.handleInputChange}
            />
            <button type="submit" className="ok">
              OK
            </button>
          </div>
          <div className="option angle">
            <ToolbarMenuOption
              label="Ângulo"
              onClick={() => {
                this.handleInputChange({
                  target: { name: 'showField', value: 'angle' },
                })
              }}
              value={
                <>
                  {`${angle}°`}
                  <ArrowCircleRightOutline
                    style={{
                      transform: `rotate(${angle}deg)`,
                    }}
                  />
                </>
              }
              icon={<AngleSvgIcon />}
            />
            <InputAngle
              angle={angle}
              handleInputChange={this.handleInputChange}
            />
            <button type="submit" className="ok">
              OK
            </button>
          </div>
          <div className="option type">
            <ToolbarMenuOption
              label="Tipo de linha"
              onClick={() => {
                this.handleInputChange({
                  target: { name: 'showField', value: 'type' },
                })
              }}
              value={type}
              icon={<TypeSvgIcon />}
            />
            <InputType type={type} handleInputChange={this.handleInputChange} />
            <button type="submit" className="ok">
              OK
            </button>
          </div>
          <div className="option delete">
            <ToolbarMenuOption
              label="Apagar linha"
              onClick={this.onDelete}
              icon={<TrashOutline />}
            />
          </div>
          <div className="option cancel">
            <ToolbarMenuOption
              label="Cancelar edição"
              onClick={onCancel}
              icon={<XOutline />}
            />
          </div>
        </form>
      </>
    )
  }
}

export default connect(null, {
  onLineOk: drawingActions.onLineFormOk,
  onDeleteLine: drawingActions.onDeleteLine,
  onCancel: actions.onCancel,
  onAddDoor: drawingActions.onAddDoor,
  onAddWindow: drawingActions.onAddWindow,
  onAddElectric: drawingActions.onAddElectric,
  setTempEntity: actions.setTempEntity,
})(LineForm)
