import round from './roundNumber'

class Point {
  readonly x: number

  readonly y: number

  constructor(x = 0, y = 0) {
    this.x = round(x)
    this.y = round(y)
  }
}

export default Point
