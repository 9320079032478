import React from 'react'
import { useSelector, connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import PropertiesForm from '../components/PropertiesForm'
import { getProject } from '../features/project/selectors'
import { selectors as propertiesSelectors } from '../features/properties'
import { actions as drawingActions } from '../features/drawing'
import { getDrawing } from '../features/drawing/selectors'
import ExportDrawing from '../components/ExportDrawing'

const DrawingProperties: React.FC = (props: any) => {
  const { id, drawingId } = useParams()
  const project = useSelector(getProject)(id)
  const drawing = useSelector(getDrawing)(drawingId)
  const properties = useSelector(propertiesSelectors.getProperties)(
    drawing.propertiesId
  )
  const history = useHistory()
  const { onDeleteDrawing } = props
  const onDelete = () => {
    onDeleteDrawing(drawing).then(() => {
      window.alert('Desenho excluído')
      history.push(`/projects/${id}`)
    })
  }

  return (
    <div className="page drawing properties">
      <PropertiesForm
        properties={properties}
        onDelete={onDelete}
        projectTitle={project.title}
      >
        <ExportDrawing
          drawing={drawing}
          properties={properties}
          projectTitle={project.title}
        />
      </PropertiesForm>
    </div>
  )
}

export default connect(null, {
  onDeleteDrawing: drawingActions.onDeleteDrawing,
})(DrawingProperties)
