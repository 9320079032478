import * as ActionTypes from './actionTypes'
import { SAVE_PROPERTIES } from '../properties/actionTypes'
import Project from './Project'
import Properties from '../properties/Properties'
import { NEW_DRAWING } from '../drawing/actionTypes'

const initialState = {
  0: {
    id: 0,
    propertiesId: 1,
    drawingIds: [],
    title: 'Novo Projeto',
  },
}

const onSaveProperties = (state: any, properties: Properties) => {
  const oldProject = Object.values(state).filter(
    (p: any) => p.propertiesId === properties.id
  )[0] as Project
  const project = {
    ...oldProject,
    title: properties.title,
  }
  const newState = { ...state }
  newState[project.id] = { ...project, title: project.title }
  return newState
}

const onNewProject = (state: any, { project }: any) => {
  const newState = { ...state }
  newState[project.id] = project
  return newState
}

const onDelete = (state: any, { project }: any) => {
  const newState = { ...state }
  newState[project.id].deleted = true
  return newState
}

const onUndelete = (state: any, { project }: any) => {
  const newState = { ...state }
  newState[project.id].deleted = false
  return newState
}

const onNewDrawing = (state: any, {project, drawing}: any) => {
  const newState = { ...state }
  newState[project.id].drawingIds = [...project.drawingIds, drawing.id]
  return newState
}

const projectReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SAVE_PROPERTIES:
      return onSaveProperties(state, action.payload.properties)
    case NEW_DRAWING:
      return onNewDrawing(state, action.payload)
    case ActionTypes.NEW_PROJECT:
      return onNewProject(state, action.payload)
    case ActionTypes.DELETE_PROJECT:
      return onDelete(state, action.payload)
    case ActionTypes.UNDELETE_PROJECT:
      return onUndelete(state, action.payload)
    default:
      return state
  }
}

export default projectReducer
