import Block from '../drawing/Block'
import { DrawingLine } from '../drawing/Line'

export const getWindowLines = (block: Block, line: DrawingLine) => {
  const window = block
  const windowThick = 0.04
  const a1 = {
    x: line.p1.x + window.pos * Math.cos(line.angle),
    y: line.p1.y + window.pos * Math.sin(line.angle),
  }
  const a0 = {
    x: a1.x + windowThick * Math.cos(line.angle - Math.PI / 2),
    y: a1.y + windowThick * Math.sin(line.angle - Math.PI / 2),
  }
  const a2 = {
    x: a1.x + windowThick * Math.cos(line.angle + Math.PI / 2),
    y: a1.y + windowThick * Math.sin(line.angle + Math.PI / 2),
  }
  const b1 = {
    x: a1.x + windowThick * Math.cos(line.angle),
    y: a1.y + windowThick * Math.sin(line.angle),
  }
  const b0 = {
    x: b1.x + windowThick * Math.cos(line.angle - Math.PI / 2),
    y: b1.y + windowThick * Math.sin(line.angle - Math.PI / 2),
  }
  const b2 = {
    x: b1.x + windowThick * Math.cos(line.angle + Math.PI / 2),
    y: b1.y + windowThick * Math.sin(line.angle + Math.PI / 2),
  }
  const d1 = {
    x: a1.x + window.width * Math.cos(line.angle),
    y: a1.y + window.width * Math.sin(line.angle),
  }
  const d0 = {
    x: d1.x + windowThick * Math.cos(line.angle - Math.PI / 2),
    y: d1.y + windowThick * Math.sin(line.angle - Math.PI / 2),
  }
  const d2 = {
    x: d1.x + windowThick * Math.cos(line.angle + Math.PI / 2),
    y: d1.y + windowThick * Math.sin(line.angle + Math.PI / 2),
  }
  const c1 = {
    x: a1.x + (window.width - windowThick) * Math.cos(line.angle),
    y: a1.y + (window.width - windowThick) * Math.sin(line.angle),
  }
  const c0 = {
    x: c1.x + windowThick * Math.cos(line.angle - Math.PI / 2),
    y: c1.y + windowThick * Math.sin(line.angle - Math.PI / 2),
  }
  const c2 = {
    x: c1.x + windowThick * Math.cos(line.angle + Math.PI / 2),
    y: c1.y + windowThick * Math.sin(line.angle + Math.PI / 2),
  }
  return [
    { p1: a0, p2: a2 },
    { p1: a2, p2: d2 },
    { p1: d2, p2: d0 },
    { p1: d0, p2: a0 },
    { p1: b0, p2: b2 },
    { p1: c0, p2: c2 },
    { p1: b1, p2: c1 },
  ]
}
