import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  TrashOutline,
  InformationCircleOutline,
  QuestionMarkCircleOutline,
  ChatAlt2Outline,
} from 'heroicons-react'
import LoggedUser from './LoggedUser'

const Menu: React.FC<{}> = () => (
  <div className="menu">
    <LoggedUser />
    <NavLink to="/trash">
      <TrashOutline />
      Itens excluídos
    </NavLink>
    <div style={{ height: '85px' }} />
    <a
      href="https://archimila.com.br/foruns/"
      rel="noopener noreferrer"
      target="_blank"
    >
      <ChatAlt2Outline /> Fóruns
    </a>
    <a
      href="https://archimila.com.br/sobre/"
      rel="noopener noreferrer"
      target="_blank"
    >
      <InformationCircleOutline /> Sobre
    </a>
    <a
      href="https://archimila.com.br/foruns/forum/archimila-app/"
      rel="noopener noreferrer"
      target="_blank"
    >
      <QuestionMarkCircleOutline /> Ajuda
    </a>
    <div style={{ height: '35px' }} />
    <div className="version">Versão {process.env.REACT_APP_VERSION}</div>
  </div>
)

export default Menu
