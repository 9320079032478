import React, { forwardRef, Ref, RefObject } from 'react'
import { XCircle } from 'heroicons-react'

const clearText = (event: any, ref: Ref<HTMLInputElement>) => {
  if (event) {
    event.preventDefault()
  }
  const refObject = ref as RefObject<HTMLInputElement>
  if (refObject.current) {
    refObject.current.value = ''
    refObject.current.focus()
  }
}
interface InputTextProps {
  name: string
  label: string
}
const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (props: InputTextProps, ref: Ref<HTMLInputElement>) => (
    <>
      <label className="label" htmlFor={props.name}>
        {props.label}
      </label>
      <div className="input-text">
        <input className="input" name={props.name} ref={ref} />
        <button
          type="button"
          className="input__clear-text"
          onClick={event => clearText(event, ref)}
        >
          <XCircle />
        </button>
      </div>
    </>
  )
)

export default InputText
