import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { TrashOutline, XCircleOutline } from 'heroicons-react'
import Properties from '../features/properties/Properties'
import InputText from './forms/InputText'
import TextArea from './forms/TextArea'
import { onSaveProperties } from '../features/properties/actions'

interface PropertiesFormProps extends RouteComponentProps {
  properties: Properties
  onSaveProperties: (properties: Properties, history: any) => void
  onDelete: () => void
  projectTitle?: string
  children?: any
}
class PropertiesForm extends React.Component<PropertiesFormProps, {}, {}> {
  private title: string

  private refTitle: React.RefObject<HTMLInputElement>

  private refNotes: React.RefObject<HTMLTextAreaElement>

  constructor(props: PropertiesFormProps) {
    super(props)
    this.title = props.properties.title || ''
    this.refTitle = React.createRef<HTMLInputElement>()
    this.refNotes = React.createRef<HTMLTextAreaElement>()
    this.onSave = this.onSave.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onBack = this.onBack.bind(this)
  }

  componentDidMount() {
    const { properties } = this.props
    if (this.refTitle.current)
      this.refTitle.current.value = properties.title || ''
    if (this.refNotes.current)
      this.refNotes.current.value = properties.notes || ''
  }

  onBack() {
    const { history } = this.props
    history.goBack()
  }

  onSave() {
    const { properties, history, onSaveProperties } = this.props
    const propertiesToSave = {
      id: properties.id,
      title: this.refTitle.current ? this.refTitle.current.value : '',
      notes: this.refNotes.current ? this.refNotes.current.value : '',
    }
    onSaveProperties(propertiesToSave, history)
  }

  onDelete(event: any) {
    event.preventDefault()
    const { onDelete } = this.props
    onDelete()
  }

  onSubmit(message: string, event: any) {
    if (event) {
      event.preventDefault()
    }
    switch (message) {
      case 'SAVE':
        this.onSave()
        break
      case 'BACK':
        this.onBack()
        break
      default:
        break
    }
  }

  render() {
    const { projectTitle, children } = this.props
    return (
      <div className="properties-form view">
        <div className="navigation-bar">
          <button type="button" className="option left" onClick={this.onBack}>
            <XCircleOutline />
            Fechar
          </button>
          <button
            type="button"
            className="option right save"
            onClick={this.onSave}
          >
            Salvar
          </button>
          <span className={projectTitle ? 'title-subtitle' : ''}>
            <h1>
              {this.title}
              <small>{projectTitle || ''}</small>
            </h1>
          </span>
        </div>
        <form onSubmit={event => this.onSubmit('SAVE', event)} className="form">
          <InputText ref={this.refTitle} label="Título" name="title" />
          <TextArea ref={this.refNotes} label="Notas" name="notes" />
          {children && children}
          <label htmlFor="deleteButton" className="label">
            Excluir
          </label>
          <button
            id="deleteButton"
            className="delete form__group form__button"
            type="button"
            onClick={this.onDelete}
          >
            <TrashOutline />
            Excluir
          </button>
        </form>
      </div>
    )
  }
}

export default withRouter(connect(null, { onSaveProperties })(PropertiesForm))
