import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from './rootReducer'
import localStorageMiddleware from './localStorageMiddleware'

export const history = createBrowserHistory()

const configureStoreProd = (preloadedState: any) =>
  createStore(
    rootReducer(history),
    preloadedState,
    applyMiddleware(thunk, routerMiddleware(history), localStorageMiddleware)
  )

export default configureStoreProd
