import React from 'react'
import { useSelector, connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { CheveronLeftOutline } from 'heroicons-react'
import {
  selectors as projectSelectors,
  actions as projectActions,
} from '../features/project'
import {
  selectors as drawingSelectors,
  actions as drawingActions,
} from '../features/drawing'
import Project from '../features/project/Project'
import Drawing from '../features/drawing/Drawing'

const Trash: React.FC<any> = (props: any) => {
  const projects = useSelector(projectSelectors.listDeletedProjects)
  const drawings = useSelector(drawingSelectors.listDeletedDrawings)
  const { onUndeleteProject, onUndeleteDrawing } = props
  const onUndeleteProjectButtonClick = (project: Project) => {
    onUndeleteProject(project).then((undeletedProject: Project) => {
      window.alert(`Projeto "${undeletedProject.title}" restaurado`)
    })
  }
  const onUndeleteDrawingButtonClick = (drawing: Drawing) => {
    onUndeleteDrawing(drawing).then((undeletedDrawing: Drawing) => {
      window.alert(`Desenho "${undeletedDrawing.title}" restaurado`)
    })
  }
  return (
    <div className="page trash form">
      <div className="navigation-bar">
        <NavLink to="/projects" className="option left">
          <CheveronLeftOutline />
        </NavLink>
        <h1>Lixeira</h1>
      </div>
      <div>
        <label className="label">Projetos:</label>
        <div className="deleted-items form__group">
          {projects.map(project => (
            <div key={project.id} className="form__button">
              {project.title}
              <button
                type="button"
                onClick={() => onUndeleteProjectButtonClick(project)}
              >
                Restaurar
              </button>
            </div>
          ))}
        </div>
        <label className="label">Desenhos:</label>
        <div className="deleted-items form__group">
          {drawings.map(drawing => (
            <div key={drawing.id} className="form__button">
              {drawing.title}
              <button
                type="button"
                onClick={() => onUndeleteDrawingButtonClick(drawing)}
              >
                Restaurar
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default connect(null, {
  onUndeleteProject: projectActions.onUndeleteProject,
  onUndeleteDrawing: drawingActions.onUndeleteDrawing,
})(Trash)
