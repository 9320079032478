import { resetIds } from '../ids'

export const saveToLocalStorage = (estado: any) => {
  const jsonProjects = JSON.stringify(estado.projects)
  window.localStorage.setItem('projects', jsonProjects)

  const jsonDrawings = JSON.stringify(estado.drawings)
  window.localStorage.setItem('drawings', jsonDrawings)

  const jsonProperties = JSON.stringify(estado.properties)
  window.localStorage.setItem('properties', jsonProperties)

  console.log('saved!', { jsonProjects, jsonDrawings, jsonProperties })
}

const loadOrUndefined: any = (itemName: string) => {
  const loadedItem = window.localStorage.getItem(itemName)
  if (loadedItem && loadedItem !== 'undefined') {
    try {
      return JSON.parse(loadedItem)
    } catch (error) {
      console.log(error)
    }
  }
  return undefined
}

export const loadFromLocalStorage = () => {
  try {
    const state = {
      projects: loadOrUndefined('projects'),
      drawings: loadOrUndefined('drawings'),
      properties: loadOrUndefined('properties'),
    }
    resetIds([state.projects, state.drawings, state.properties])
    return state
  } catch (error) {
    console.log('Error loding from local storage', error)
  }
  return { projects: undefined, drawings: undefined, properties: undefined }
}
