import { LOCATION_CHANGE } from 'connected-react-router'
import Line, { withParent, newLine } from '../drawing/Line'
import {
  ON_NEW_LINE,
  ON_SELECT_LINE,
  ON_SELECT_BLOCK,
  ON_SELECT_DIMENSION,
  ON_CANCEL,
  ON_ZOOM,
  ON_SET_TEMP,
} from './actionTypes'
import Block, { DrawingDimension } from '../drawing/Block'
import {
  STATE_ADD_LINE,
  STATE_EDIT_LINE,
  STATE_EDIT_BLOCK,
  DrawingPageState,
} from './types'
import {
  LINE_FORM_OK,
  LINE_FORM_DELETE,
  BLOCK_SAVE,
  BLOCK_DELETE,
  NEW_BLOCK,
} from '../drawing/actionTypes'
import { lastLine } from '../drawing/Drawing'
import { getGlobalIds } from '../ids'

const initialState: DrawingPageState = {
  lastLine: undefined,
  selection: undefined,
  currentState: STATE_ADD_LINE,
  zoom: 1,
  temporaryEntity: undefined,
}

// const onSelectDrawing = (
//   state: DrawingPageState,
//   drawing: Drawing
// ): DrawingPageState => {
//   return {
//     ...state,
//     lastLine:
//       drawing.lines && drawing.lines.length > 0
//         ? drawing.lines[drawing.lines.length - 1]
//         : undefined,
//   }
// }

const onNewLine = (
  state: DrawingPageState,
  { angle, lastLineSelected }: any
): DrawingPageState => {
  const line = {
    id: getGlobalIds().nextId(),
    ...withParent(newLine(0, angle), lastLineSelected),
  }
  return {
    ...state,
    lastLine: line,
    selection: line,
    currentState: STATE_EDIT_LINE,
  }
}

const onSelectLine = (
  state: DrawingPageState,
  line: Line
): DrawingPageState => {
  return {
    ...state,
    lastLine: line,
    selection: line,
    currentState: STATE_EDIT_LINE,
  }
}

const onSelectBlock = (
  state: DrawingPageState,
  block: Block
): DrawingPageState => {
  return {
    ...state,
    selection: block,
    currentState: STATE_EDIT_BLOCK,
  }
}

const onSelectDimension = (
  state: DrawingPageState,
  dimension: DrawingDimension
): DrawingPageState => {
  return {
    ...state,
    selection: dimension,
    currentState: STATE_EDIT_BLOCK,
  }
}

const onLineOk = (state: DrawingPageState): DrawingPageState => {
  return {
    ...state,
    selection: undefined,
    currentState: STATE_ADD_LINE,
    temporaryEntity: undefined,
  }
}

const onBlockCancel = (state: DrawingPageState): DrawingPageState => {
  let selection = undefined
  if (
    (state.selection as any).lineId === state.lastLine?.id ||
    (state.selection as any)?.block?.lineId === state.lastLine?.id
  ) {
    selection = state.lastLine
  }
  return {
    ...state,
    selection,
    currentState: selection ? STATE_EDIT_LINE : STATE_ADD_LINE,
    temporaryEntity: undefined,
  }
}

const onCancel = (state: DrawingPageState): DrawingPageState => {
  if (state.currentState === STATE_EDIT_BLOCK) {
    return onBlockCancel(state)
  }
  return {
    ...state,
    selection: undefined,
    currentState: STATE_ADD_LINE,
    temporaryEntity: undefined,
  }
}

const onDeleteLine = (
  state: DrawingPageState,
  { drawing }: any
): DrawingPageState => {
  return onCancel({
    ...state,
    lastLine: lastLine(drawing),
    temporaryEntity: undefined,
  })
}

const onNewBlock = (
  state: DrawingPageState,
  block: Block
): DrawingPageState => {
  return {
    ...state,
    selection: block,
    currentState: STATE_EDIT_BLOCK,
  }
}

const onZoom = (state: DrawingPageState, { zoom }: any): DrawingPageState => {
  return {
    ...state,
    zoom: Math.min(Math.max(state.zoom + zoom, 0), 5),
  }
}

const onSetTempEntity = (state: DrawingPageState, entity: Block | Line | undefined): DrawingPageState => {
  return {
    ...state,
    temporaryEntity: entity,
  }
}

const drawingPageReducer = (
  state = initialState,
  action: any
): DrawingPageState => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return initialState
    case ON_NEW_LINE:
      return onNewLine(state, action.payload)
    case ON_SELECT_LINE:
      return onSelectLine(state, action.payload.line)
    case ON_SELECT_BLOCK:
      return onSelectBlock(state, action.payload.block)
    case ON_SELECT_DIMENSION:
      return onSelectDimension(state, action.payload.dimension)
    case LINE_FORM_OK:
      return onLineOk(state)
    case ON_CANCEL:
      return onCancel(state)
    case LINE_FORM_DELETE:
      return onDeleteLine(state, action.payload)
    case NEW_BLOCK:
      return onNewBlock(state, action.payload.block)
    case ON_ZOOM:
      return onZoom(state, action.payload)
    case BLOCK_SAVE:
    case BLOCK_DELETE:
      return onBlockCancel(state)
    case ON_SET_TEMP:
      return onSetTempEntity(state, action.payload.entity)
    // case SAVE_PROPERTIES:
    //   return onSaveProperties(state, action.payload.properties)
    // case ActionTypes.NEW_PROJECT:
    //   return onNewProject(state, action.payload)
    // case ActionTypes.DELETE_PROJECT:
    //   return onDelete(state, action.payload)
    // case ActionTypes.UNDELETE_PROJECT:
    //   return onUndelete(state, action.payload)
    default:
      return state
  }
}

export default drawingPageReducer
