import * as ActionTypes from './actionTypes'
import Drawing, { lastLine } from './Drawing'
import Line, { updateLine, deleted, withParent } from './Line'
import { getGlobalIds } from '../ids'

const initialState = {
  // 0: {
  //     id: 0,
  //     lines: []
  //     blocks: []
  //     propertiesId: 1,
  // },
  // 1: {
  //     id: 1,
  //     lines: []
  //     blocks: []
  //     propertiesId: 2,
  // }
}

const onNewDrawing = (state: any, { drawing }: any) => {
  const newState = { ...state }
  newState[drawing.id] = drawing
  return newState
}

const onOk = (state: any, { drawing, oldLine, length, angle, type }: any) => {
  const id = oldLine.id ? oldLine.id : getGlobalIds().nextId()
  let newLine = updateLine({ id, ...oldLine }, length, angle, type)
  if (!newLine.parent) {
    newLine = withParent(newLine, lastLine(state[drawing.id]))
  }
  const newState = { ...state }
  newState[drawing.id].lines = [
    ...state[drawing.id].lines.filter((line: Line) => line.id !== oldLine.id),
    newLine,
  ]
  return newState
}

const onDeleteLine = (state: any, { drawing, line }: any) => {
  if (!line || !line.id) {
    console.log('Nothing to delete')
    return state
  }

  const newState = { ...state }
  newState[drawing.id].lines = state[drawing.id].lines.map((l: Line) =>
    l.id === line.id ? deleted(l) : l
  )
  return newState
}

const onBlockSave = (state: any, { drawingId, block }: any) => {
  const id = block.id ? block.id : getGlobalIds().nextId()
  const blockToSave = { ...block, id }
  const newState = { ...state }
  newState[drawingId].blocks = state[drawingId].blocks
    ? [
        ...state[drawingId].blocks.filter((b: any) => b.id !== blockToSave.id),
        blockToSave,
      ]
    : [blockToSave]
  return newState
}

const onBlockDelete = (state: any, { drawingId, block }: any) => {
  const newState = { ...state }
  newState[drawingId].blocks = [
    ...state[drawingId].blocks.filter((b: any) => b.id !== block.id),
  ]
  return newState
}

const onDelete = (state: any, drawing: Drawing) => {
  const newState = { ...state }
  newState[drawing.id].deleted = true
  return newState
}

const onUndelete = (state: any, element: any) => {
  const newState = { ...state }
  newState[element.id].deleted = false
  return newState
}

const drawingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.LINE_FORM_OK:
      return onOk(state, action.payload)
    case ActionTypes.LINE_FORM_DELETE:
      return onDeleteLine(state, action.payload)
    case ActionTypes.NEW_DRAWING:
      return onNewDrawing(state, action.payload)
    case ActionTypes.DELETE_DRAWING:
      return onDelete(state, action.payload.drawing)
    case ActionTypes.UNDELETE_DRAWING:
      return onUndelete(state, action.payload.drawing)
    case ActionTypes.NEW_BLOCK:
    case ActionTypes.BLOCK_SAVE:
      return onBlockSave(state, action.payload)
    case ActionTypes.BLOCK_DELETE:
      return onBlockDelete(state, action.payload)
    default:
      return state
  }
}

export default drawingReducer
