import React, { forwardRef, Ref } from 'react'

interface TextAreaProps {
  name: string
  label: string
}
const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props: TextAreaProps, ref: Ref<HTMLTextAreaElement>) => (
    <>
      <label className="label" htmlFor={props.name}>
        {props.label}
      </label>
      <div className="input-text">
        <textarea className="input" name={props.name} ref={ref} />
      </div>
    </>
  )
)

export default TextArea
