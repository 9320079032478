import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  TrashOutline,
  XOutline,
  SwitchHorizontalOutline,
} from 'heroicons-react'
import { ReactComponent as LengthSvgIcon } from './LineForm/svg/length.svg'
import ToolbarMenuOption from './ToolbarMenuOption'
import Block, { DrawingDimension } from '../features/drawing/Block'
import Line from '../features/drawing/Line'
import { actions } from '../features/drawingPage'
import round from '../features/drawing/roundNumber'
import { getBlockPositionOptions } from './svg/blocks/GenericBlock'

const ToolbarInput = ({ id, value, handleInputChange }: any) => (
  <input
    className="input--toolbar"
    id={id}
    name={id}
    type="number"
    step="0.01"
    value={value}
    onChange={handleInputChange}
  />
)

const RotSelect: React.FC<any> = ({ block, rot, handleInputChange }: any) => {
  return (
    <select
      className="select--toolbar"
      id="rot"
      name="rot"
      value={rot}
      onChange={handleInputChange}
    >
      {getBlockPositionOptions(block).map(option => (
        <option key={option} value={option}>
          Posição {option + 1}
        </option>
      ))}
    </select>
  )
}

export interface BlockFormProps {
  block: Block
  line: Line
  onCancel: () => {}
  onSaveBlock: (block: Block) => {}
  onDeleteBlock: (block: Block) => {}
  onSelectDimension: (dimension: DrawingDimension) => {}
  setTempEntity: (entity: Line | Block | undefined) => {}
  selectedDimensionIndex?: number
}
type BlockFormState = {
  pos: string
  width: string
  rot: string
}

const fields = ['pos', 'width', 'opposite', 'rot']

class BlockForm extends Component<BlockFormProps, BlockFormState, {}> {
  constructor(props: BlockFormProps) {
    super(props)
    const { block } = props
    this.state = {
      pos: String(block.pos),
      width: String(block.width),
      rot: String(block.rot),
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.onOk = this.onOk.bind(this)
    this.focus = this.focus.bind(this)
    this.selectDimension = this.selectDimension.bind(this)
  }

  componentDidMount() {
    this.focus()
  }

  componentDidUpdate() {
    this.focus()
  }

  onOk(event: any) {
    event.preventDefault()
    const { pos, width, rot } = this.state
    const { onSaveBlock, block } = this.props
    onSaveBlock({
      ...block,
      pos: pos !== '' ? +pos : 0,
      width: width !== '' ? +width : 0,
      rot: +rot,
    })
  }

  focus() {
    const { selectedDimensionIndex } = this.props
    const showField =
      fields[selectedDimensionIndex !== undefined ? selectedDimensionIndex : 0]
    if (showField !== undefined) {
      document.getElementById(showField)?.focus()
    }
  }

  handleInputChange(event: any) {
    const { name, value } = event.target
    this.setState(
      (prevState: BlockFormState) => {
        return {
          ...prevState,
          [name]: value,
        }
      },
      () => {
        const { block, setTempEntity } = this.props
        const { pos, width, rot } = this.state
        setTempEntity({
          ...block,
          pos: pos !== '' ? +pos : 0,
          width: width !== '' ? +width : 0,
          rot: +rot,
        })
      }
    )
  }

  selectDimension(index: number) {
    if (index === undefined || index === null) {
      return
    }
    const { block, onSelectDimension, selectedDimensionIndex } = this.props
    const showField =
      fields[selectedDimensionIndex !== undefined ? selectedDimensionIndex : 1]
    const nextShowField = fields[index]
    const nextIndex = showField === nextShowField ? -1 : index
    onSelectDimension({
      block,
      index: nextIndex,
      p1: { x: 0, y: 0 },
      p2: { x: 0, y: 0 },
      length: 0,
      angle: 0,
      perpAngle: 0,
      selected: false,
    })
  }

  render() {
    const { pos, width, rot } = this.state
    const {
      block,
      line,
      onDeleteBlock,
      onCancel,
      selectedDimensionIndex,
    } = this.props
    const showField =
      fields[selectedDimensionIndex !== undefined ? selectedDimensionIndex : 0]

    const oppositePos = round(line.length - +pos - +width)
    return (
      <form
        className={`${showField} block-type-${block.type} line-form`}
        onSubmit={this.onOk}
      >
        <div className="option pos">
          <ToolbarMenuOption
            label="Distância 1"
            onClick={() => this.selectDimension(0)}
            value={`${pos}m`}
            icon={<LengthSvgIcon />}
          />
          <ToolbarInput
            id="pos"
            value={pos}
            handleInputChange={this.handleInputChange}
          />
          <button type="submit" className="ok">
            OK
          </button>
        </div>
        <div className="option width">
          <ToolbarMenuOption
            label="Largura"
            onClick={() => this.selectDimension(1)}
            value={`${width}m`}
            icon={<LengthSvgIcon />}
          />
          <ToolbarInput
            id="width"
            value={width}
            handleInputChange={this.handleInputChange}
          />
          <button type="submit" className="ok">
            OK
          </button>
        </div>
        <div className="option opposite">
          <ToolbarMenuOption
            label="Distância 2"
            onClick={() => this.selectDimension(2)}
            value={`${oppositePos}m`}
            icon={<LengthSvgIcon />}
          />
          <ToolbarInput
            id="opposite"
            value={oppositePos}
            handleInputChange={(event: any) => {
              this.handleInputChange({
                target: {
                  name: 'pos',
                  value: line.length - +width - +event.target.value,
                },
              })
            }}
          />
          <button type="submit" className="ok">
            OK
          </button>
        </div>
        <div className="option rot">
          <ToolbarMenuOption
            label={`Posição ${1 + +rot}`}
            onClick={() => this.selectDimension(3)}
            value={`Posição ${1 + +rot}`}
            icon={<SwitchHorizontalOutline />}
          />
          <RotSelect
            block={block}
            rot={rot}
            handleInputChange={this.handleInputChange}
          />
          <button type="submit" className="ok">
            OK
          </button>
        </div>
        <div className="option delete">
          <ToolbarMenuOption
            id="delete"
            label="Apagar bloco"
            onClick={() => onDeleteBlock(block)}
            icon={<TrashOutline />}
          />
        </div>
        <div className="option cancel">
          <ToolbarMenuOption
            label="Cancelar edição"
            onClick={onCancel}
            id="delete"
            icon={<XOutline />}
          />
        </div>
      </form>
    )
  }
}

export default connect(null, {
  onCancel: actions.onCancel,
  onSelectDimension: actions.onSelectDimension,
  setTempEntity: actions.setTempEntity,
})(BlockForm)
