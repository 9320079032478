import configureStoreProd, {
  history as prodHistory,
} from './configureStoreProd'
import configureStoreDev, { history as devHistory } from './configureStoreDev'
import { loadFromLocalStorage } from '../features/localStorage/localStorage'

const configureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev
export const history =
  process.env.NODE_ENV === 'production' ? prodHistory : devHistory

let estado = {}
estado = { ...estado, ...loadFromLocalStorage() }

export const store = configureStore(estado)
