import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { TransitionGroup, Transition } from 'react-transition-group'

import { play, exit } from './components/animatedRouter/timelines'
import Projects from './pages/Projects'
import Login from './pages/Login'
import Project from './pages/Project'
import ProjectProperties from './pages/ProjectProperties'
import DrawingProperties from './pages/DrawingProperties'
import Drawing from './pages/Drawing'
import Trash from './pages/Trash'

let lastLocation = '/'

const App: React.FC = () => {
  return (
    <div className="app">
      <Route
        render={({ location }) => {
          const { pathname, key, state } = location
          // Se o estado tiver um "background",
          // vamos mostrar o background no Switch por trás,
          // e mostrar o location atual por cima
          const background = state && (state as any).background
          let component:
            | typeof ProjectProperties
            | typeof DrawingProperties = ProjectProperties
          let path = ''
          if (background) {
            const isProjectProperties = pathname.indexOf('drawing') === -1
            component = isProjectProperties
              ? ProjectProperties
              : DrawingProperties
            path = isProjectProperties
              ? '/projects/:id/properties'
              : '/projects/:id/drawing/:drawingId/properties'
          }
          const from = lastLocation
          lastLocation = pathname
          return (
            <TransitionGroup component={null}>
              <Transition
                key={key}
                appear
                onEnter={(node, appears) => play(pathname, node, appears, from)}
                onExit={node => exit(node)}
                timeout={{ enter: 750, exit: 150 }}
              >
                <>
                  <Switch location={background || location}>
                    <Route exact path="/" component={Projects} />
                    <Route exact path="/projects" component={Projects} />
                    <Route exact path="/projects/:id" component={Project} />
                    <Route
                      exact
                      path="/projects/:id/drawing/:drawingId"
                      component={Drawing}
                    />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/trash" component={Trash} />
                  </Switch>
                  {/* Show the modal when a background page is set */}
                  {background && (
                    <>
                      <span className="pathname">{background.pathname}</span>
                      <span className="location">{pathname}</span>
                    </>
                  )}
                  {background && (
                    <Switch location={location}>
                      <Route path={path} component={component} />
                    </Switch>
                  )}
                </>
              </Transition>
            </TransitionGroup>
          )
        }}
      />
    </div>
  )
}

export default App
