class LineType {
  constructor(readonly name: string, readonly stroke: string) {}
}

export const CONTINUOUS = new LineType('Continuo', '1, 0')
export const DASHED = new LineType('Tracejado', '0.4, 0.2')
export const DOT = new LineType('Pontilhado', '0.01, 0.15')
export const DASHDOT = new LineType('TracoPonto', '0.4, 0.2, 0.01, 0.2')
export const TRANSPARENT = new LineType('Transparente', '1, 0')
export const TYPES = [CONTINUOUS, DASHED, DOT, DASHDOT, TRANSPARENT]

export const getByName = (name: string) => {
  return TYPES.filter(lineType => lineType.name === name)[0]
}

export default LineType
