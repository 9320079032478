import React, { Component } from 'react'
import {
  MailOutline,
  DocumentDownloadOutline,
  DocumentOutline,
} from 'heroicons-react'
import Drawing from '../features/drawing/Drawing'
import Properties from '../features/properties/Properties'
import DxfBuilder from '../features/dxf/DxfBuilder'
import { calculateLines } from '../features/drawing/Line'
import { sendDrawingEmail } from '../features/email'

interface ExportDrawingProps {
  drawing: Drawing
  properties: Properties
  projectTitle: string
}
type ExportDrawingState = {
  loading: boolean
  file?: string
}
class ExportDrawing extends Component<ExportDrawingProps, ExportDrawingState> {
  constructor(props: ExportDrawingProps) {
    super(props)
    this.state = {
      file: undefined,
      loading: false,
    }
    this.generateDXF = this.generateDXF.bind(this)
    this.onSendEmail = this.onSendEmail.bind(this)
  }

  onSendEmail() {
    this.setState({ loading: true })
    const { projectTitle, properties } = this.props
    const { file } = this.state
    if (file == null) {
      alert('Erro ao gerar arquivo')
      return
    }
    sendDrawingEmail(projectTitle, file, properties)
      .then((response: any) => {
        if (response.status) {
          alert(response.message)
        } else {
          let message = response.error
          switch (message) {
            case 'NOT_LOGGED_IN':
              message = 'Por favor, faça login'
              break
            case 'NOT_SENT':
              message =
                'Não foi possível enviar e-mail, tente novamente mais tarde'
              break
            default:
              break
          }
          alert(message)
        }
        this.setState({ loading: false })
      })
      .catch((error: any) => {
        alert('Erro de conexão. Tente novamente mais tarde.')
        this.setState({ loading: false })
      })
  }

  generateDXF() {
    this.setState({ loading: true })
    new Promise<string>((resolve, reject) => {
      const { drawing, properties } = this.props
      const { title } = properties
      const dxf = new DxfBuilder()
      const lines = calculateLines(drawing.lines)
      dxf.linesToDxf(title, 'Meters', lines, drawing.blocks)
      resolve(dxf.getFile())
    }).then((file: string) => {
      this.setState({
        file,
        loading: false,
      })
    })
  }

  render() {
    const { file, loading } = this.state
    const { properties } = this.props
    const fileName = `${properties.title}.dxf`
    return (
      <div className="export-drawing">
        <label className="label">Exportar</label>
        {!file && !loading && (
          <button
            type="button"
            onClick={this.generateDXF}
            className="form__group form__button"
          >
            <DocumentOutline />
            DXF
          </button>
        )}
        {loading && (
          <div className=" form__group form__button">
            <img src="imgs/loading.gif" alt="Carregando..." />
            Aguarde...
          </div>
        )}
        {file && (
          <div className=" form__group ">
            <button
              type="button"
              className="form__button"
              onClick={this.onSendEmail}
            >
              <MailOutline />
              <span>Enviar DXF por E-mail</span>
            </button>
            <a
              className="form__button"
              href={`data:application/dxf;charset=utf-8,${file}`}
              download={fileName}
            >
              <DocumentDownloadOutline />
              Download {fileName}
            </a>
          </div>
        )}
      </div>
    )
  }
}

export default ExportDrawing
