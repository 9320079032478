import React from 'react'
import Line, {
  DrawingLine,
  calculateLine,
  calculateLines,
} from '../../features/drawing/Line'
import { TRANSPARENT } from '../../features/drawing/LineType'
import Point from '../../features/drawing/Point'
import Block, { DrawingDimension } from '../../features/drawing/Block'
import GenericBlock from './blocks/GenericBlock'
import Drawing from '../../features/drawing/Drawing'

const isSelectionReferringBlock = (selection: any, block: Block) => {
  return selection?.id === block?.id || selection?.block?.id === block?.id
}

type SvgLineProps = {
  line: DrawingLine
  onLineClick: (line: DrawingLine) => void
  selected: boolean
  fadeOut: boolean
}
const SvgLine: React.FC<SvgLineProps> = ({
  line,
  onLineClick,
  selected,
  fadeOut,
}: SvgLineProps) => {
  return (
    <line
      x1={line.p1.x}
      y1={line.p1.y}
      x2={line.p2.x}
      y2={line.p2.y}
      strokeDasharray={line.type.stroke}
      className={`line ${selected ? 'selected' : ''} ${
        TRANSPARENT.name === line.type.name ? 'transparent' : 'not-transp'
      } ${fadeOut ? 'fade-out' : ''}`}
      onClick={() => onLineClick(line)}
    />
  )
}

// const Block = props => {
//     switch(props.block.type) {
//         case Tools.DOOR_TOOL:
//             return <Door {...props} />
//         case Tools.WINDOW_TOOL:
//             return <Window {...props}/>
//         case Tools.ELECTRIC_TOOL:
//             return <Electric {...props}/>
//         default:
//             return <></>
//     }
// }
const getViewBox = (lines: DrawingLine[]) => {
  let viewBoxCalc = { x: 0, y: 0, w: 0, h: 0 }
  lines.forEach(line => {
    viewBoxCalc.x = Math.min(viewBoxCalc.x, line.p1.x, line.p2.x)
    viewBoxCalc.w = Math.max(viewBoxCalc.w, line.p1.x, line.p2.x)
    viewBoxCalc.y = Math.min(viewBoxCalc.y, line.p1.y, line.p2.y)
    viewBoxCalc.h = Math.max(viewBoxCalc.h, line.p1.y, line.p2.y)
  })
  const margin = 1
  viewBoxCalc = {
    x: viewBoxCalc.x - margin,
    y: viewBoxCalc.y - margin,
    w: viewBoxCalc.w - viewBoxCalc.x + 2 * margin,
    h: viewBoxCalc.h - viewBoxCalc.y + 2 * margin,
  }
  return `${viewBoxCalc.x} ${viewBoxCalc.y} ${viewBoxCalc.w} ${viewBoxCalc.h}`
}

const getTempLine = (lines: DrawingLine[], line: Line) => {
  let p1 = new Point()
  if (lines.length > 0) {
    const drawingLine = lines.filter(l => l.id === line.id)[0]
    p1 = drawingLine?.p1 || lines.filter(l => l.id === line.parent)[0]?.p2
  }
  return calculateLine(p1, line)
}

interface SvgProps {
  drawing: Drawing
  onLineClick: (line: DrawingLine) => void
  lastLineSelected?: Line
  selection?: any
  onBlockClick?: (block: any) => void
  onDimensionClick?: (dimension: DrawingDimension) => void
  selectedDimension?: DrawingDimension
  temporaryEntity?: Line | Block
}
const Svg: React.FC<SvgProps> = ({
  drawing,
  onLineClick,
  lastLineSelected,
  selection,
  onBlockClick,
  onDimensionClick,
  selectedDimension,
  temporaryEntity,
}: SvgProps) => {
  const lines = calculateLines(drawing.lines)

  const lastLineOnDrawing = lastLineSelected
    ? lines.filter(l => l.id === lastLineSelected.id)[0]
    : undefined
  let point
  if (lastLineOnDrawing && !selection) {
    point = lastLineOnDrawing.p2
  } else if (drawing.lines.length <= 0) {
    point = new Point()
  }

  const tempBlock =
    (temporaryEntity as Block)?.lineId &&
    isSelectionReferringBlock(selection, temporaryEntity as Block)
      ? (temporaryEntity as Block)
      : undefined
  const tempLine =
    ((temporaryEntity as Line)?.length &&
      getTempLine(lines, temporaryEntity as Line)) ||
    (tempBlock && lines.filter(l => l.id === tempBlock.lineId)[0]) ||
    undefined
  const viewBox = getViewBox(lines)
  return (
    <svg className="svg" viewBox={viewBox}>
      {lines.map((line: DrawingLine) => {
        const selected =
          selection && line.id === selection.id && line.id !== tempLine?.id
        const fadeOut =
          selection && line.id === selection.id && line.id === tempLine?.id
        return (
          <SvgLine
            key={line.id}
            line={line}
            onLineClick={onLineClick}
            selected={selected}
            fadeOut={fadeOut}
          />
        )
      })}
      {drawing.blocks &&
        drawing.blocks
          .map((block: Block) =>
            selection && block.id === selection.id
              ? { ...block, selected: true }
              : block
          )
          .map((block: Block) => {
            const line = lines.filter(l => l.id === block.lineId)[0]
            const selected =
              !tempLine && isSelectionReferringBlock(selection, block)
            const fadeOut = tempLine && tempBlock?.id === block.id
            return (
              line && (
                <GenericBlock
                  key={block.id}
                  fadeOut={fadeOut}
                  block={block}
                  line={line}
                  onBlockClick={onBlockClick}
                  onDimensionClick={onDimensionClick}
                  selected={selected}
                  selectedDimension={selectedDimension}
                />
              )
            )
          })}
      {tempLine && tempBlock && (
        <GenericBlock
          key={tempBlock.id}
          block={tempBlock}
          line={tempLine}
          onBlockClick={() => {}}
          onDimensionClick={() => {}}
          selected
          selectedDimension={selectedDimension}
        />
      )}
      {!tempBlock && tempLine && (
        <SvgLine
          line={tempLine}
          selected
          onLineClick={() => {}}
          fadeOut={false}
        />
      )}
      {point && (
        <circle cx={point.x} cy={point.y} className="selected-point" r="1" />
      )}
    </svg>
  )
}

export default Svg
