import {
  LOGIN,
  SILENT_LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  SILENT_LOGOUT,
} from './actionTypes'
import { login, loggedUser } from './userService'

export const onLogin = (user: any) => {
  return { type: LOGIN, payload: user }
}

export const onFetchUser = () => {
  return (dispatch: Function) =>
    loggedUser()
      .then(result => {
        if (result.status) {
          dispatch(onLogin(result.data))
        } else {
          dispatch({ type: LOGOUT, payload: 'Erro no login, tente novamente' })
        }
      })
      .catch(error =>
        dispatch({ type: LOGIN_ERROR, payload: 'Erro de conexão.' })
      )
}

export const onLoginPost = (username?: string, password?: string) => {
  return (dispatch: Function) => {
    // if (username === 'admin') {
    //   setTimeout(() => {
    //     dispatch(
    //       onLogin({
    //         username: 'Matheus',
    //         avatar:
    //           'https://secure.gravatar.com/avatar/0382d967d3f64f848771e4d7f6d0f946?s=96&r=g',
    //         nicename: 'matheus',
    //       })
    //     )
    //   }, 10000)
    //   return
    // }
    if (!username || !password) {
      dispatch({
        type: LOGIN_ERROR,
        payload: 'Insira seus dados para realizar login.',
      })
      return
    }
    login(username, password)
      .then(result => {
        if (result.status) {
          onFetchUser()(dispatch)
        } else {
          let errorMessage = result.message
          if (!result.message) {
            errorMessage = 'Usuário ou senha incorretos'
          }
          dispatch({ type: LOGIN_ERROR, payload: errorMessage })
        }
      })
      .catch(error =>
        dispatch({ type: LOGIN_ERROR, payload: 'Erro de conexão.' })
      )
  }
}
export const onLogout = () => ({ type: LOGOUT })
export const onSilentlyFetchUser = () => {
  return (dispatch: Function) =>
    loggedUser()
      .then(result => {
        if (result.status) {
          dispatch({ type: SILENT_LOGIN, payload: result.data })
        } else {
          dispatch({ type: SILENT_LOGOUT, payload: '' })
        }
      })
      .catch(error => dispatch({ type: SILENT_LOGOUT, payload: '' }))
}
