import Drawing from './Drawing'

export const listDrawings = (state: any) => (projectId: any): Drawing[] => {
  const project = state.projects[projectId]
  return project.drawingIds
    .map((id: any) => state.drawings[id])
    .filter((drawing: any) => !drawing.deleted)
    .map((drawing: any) => {
      const d = {
        ...drawing,
        title: state.properties[drawing.propertiesId].title,
      }
      return d
    })
}

export const listDeletedDrawings = (state: any): Drawing[] => {
  const { drawings } = state
  return Object.keys(drawings)
    .filter((key: any) => key !== 'selected')
    .map((key: any) => drawings[key])
    .filter((drawing: any) => drawing.deleted)
    .map((drawing: any) => ({
      ...drawing,
      title: state.properties[drawing.propertiesId].title,
    }))
}

export const getDrawing = (state: any) => (id: any): Drawing => {
  return state.drawings[id]
}
