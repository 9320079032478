class IdGen {
  id: number

  constructor(ids: any[]) {
    let maxId = 1
    ids.forEach(id => {
      maxId = Math.max(maxId, id + 1)
    })

    this.id = maxId
  }

  nextId() {
    this.id += 1
    return this.id
  }
}

let globalIds = new IdGen([])

export const resetIds = (listOfObjects: any[]) => {
  const ids: any[] = listOfObjects
    .filter((object: any) => object !== undefined)
    .flatMap((object: any) => Object.keys(object).map(key => object[key]))
    .flatMap((object: any) =>
      object.lines ? [...object.lines, object] : object
    )
    .flatMap((object: any) =>
      object.blocks ? [...object.blocks, object] : object
    )
    .filter((item: any) => item && item.id)
    .map((item: any) => item.id)
  globalIds = new IdGen(ids)
}

export const getGlobalIds = () => globalIds
