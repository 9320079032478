import Block, { Path } from '../../../features/drawing/Block'
import { DrawingLine } from '../../../features/drawing/Line'

/*

 a0 *---* b0 --------------- c0 *---* d0
    |   |                       |   |
 a1 * b1*-----------------------*c1 * d1
    |   |                       |   |
 a2 *---* b2 --------------- c2 *---* d2

*/
const calculateWindow = (block: Block, line: DrawingLine): Path[] => {
  const { pos, width } = block
  const windowThick = 0.04
  const a1 = {
    x: line.p1.x + pos * Math.cos(line.angle),
    y: line.p1.y + pos * Math.sin(line.angle),
  }
  const a0 = {
    x: a1.x + windowThick * Math.cos(line.angle - Math.PI / 2),
    y: a1.y + windowThick * Math.sin(line.angle - Math.PI / 2),
  }
  const a2 = {
    x: a1.x + windowThick * Math.cos(line.angle + Math.PI / 2),
    y: a1.y + windowThick * Math.sin(line.angle + Math.PI / 2),
  }
  const b1 = {
    x: a1.x + windowThick * Math.cos(line.angle),
    y: a1.y + windowThick * Math.sin(line.angle),
  }
  const b0 = {
    x: b1.x + windowThick * Math.cos(line.angle - Math.PI / 2),
    y: b1.y + windowThick * Math.sin(line.angle - Math.PI / 2),
  }
  const b2 = {
    x: b1.x + windowThick * Math.cos(line.angle + Math.PI / 2),
    y: b1.y + windowThick * Math.sin(line.angle + Math.PI / 2),
  }
  const d1 = {
    x: a1.x + width * Math.cos(line.angle),
    y: a1.y + width * Math.sin(line.angle),
  }
  const d0 = {
    x: d1.x + windowThick * Math.cos(line.angle - Math.PI / 2),
    y: d1.y + windowThick * Math.sin(line.angle - Math.PI / 2),
  }
  const d2 = {
    x: d1.x + windowThick * Math.cos(line.angle + Math.PI / 2),
    y: d1.y + windowThick * Math.sin(line.angle + Math.PI / 2),
  }
  const c1 = {
    x: a1.x + (width - windowThick) * Math.cos(line.angle),
    y: a1.y + (width - windowThick) * Math.sin(line.angle),
  }
  const c0 = {
    x: c1.x + windowThick * Math.cos(line.angle - Math.PI / 2),
    y: c1.y + windowThick * Math.sin(line.angle - Math.PI / 2),
  }
  const c2 = {
    x: c1.x + windowThick * Math.cos(line.angle + Math.PI / 2),
    y: c1.y + windowThick * Math.sin(line.angle + Math.PI / 2),
  }
  const windowPath = `M ${a0.x} ${a0.y} L ${a2.x} ${a2.y} L ${d2.x} ${d2.y} L ${d0.x} ${d0.y} L ${a0.x} ${a0.y}
  M ${b0.x}, ${b0.y} L ${b2.x} ${b2.y} M ${c0.x}, ${c0.y} L ${c2.x} ${c2.y}`
  const windowPane = `M ${b1.x}, ${b1.y} L ${c1.x}, ${c1.y}`
  const gapPath = `M ${a1.x} ${a1.y} L ${d1.x} ${d1.y}`
  return [
    { d: gapPath, className: 'block-gap', strokeDasharray: '1 0' },
    { d: windowPath, className: 'block-path', strokeDasharray: '1 0' },
    { d: windowPane, className: 'block-alternate-path', strokeDasharray: '1 0' },
  ]
}

export const windowRotOptions = [0]

export default calculateWindow
// export interface WindowProps {
//   block: Block
//   line: DrawingLine
//   onBlockClick?: (block: Block) => void
//   selected?: boolean
//   onDimensionClick?: (dimensionIndex: number) => void
//   selectedDimensionIndex?: number
// }
// const Window: React.FC<WindowProps> = ({
//   block,
//   line,
//   onBlockClick = (b: Block) => {},
//   selected = false,
//   onDimensionClick = (dimensionIndex: number) => {},
//   selectedDimensionIndex,
// }: WindowProps) => {
//   const paths = calculateBlock(block, line)
//   const dim = getGenericDimensions(block, line, selectedDimensionIndex)
//   const selectedClassName = selected ? 'selected ' : ''
//   const onClick = (event: any) => onBlockClick(block)
//   const onDimClick = (index: number) => () => onDimensionClick(index)
//   return (
//     <>
//       {paths.map((path: Path) => (
//         <path
//           d={path.d}
//           className={`${path.className} ${selectedClassName}`}
//           strokeDasharray={path.strokeDasharray}
//           onClick={onClick}
//         />
//       ))}
//       {selected && (
//         <>
//           <Dimension dimension={dim[0]} onClick={onDimClick(0)} />
//           <Dimension dimension={dim[1]} onClick={onDimClick(1)} />
//           <Dimension dimension={dim[2]} onClick={onDimClick(2)} />
//         </>
//       )}
//     </>
//   )
// }

// export default Window
