import React, { useState } from 'react'
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'
import {
  PlusCircleOutline,
  MenuOutline,
  XOutline,
  CheveronRightOutline,
} from 'heroicons-react'
import { selectors, actions } from '../features/project'
import Project from '../features/project/Project'
import Menu from '../components/menu/Menu'

interface ProjectsProps extends RouteComponentProps {
  onNewProject: any
}

const Projects: React.FC<ProjectsProps> = (props: ProjectsProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const projects = useSelector(selectors.listProjects)
  //const history = useHistory()
  const { history, onNewProject } = props
  const onClickNewProject = () =>
    onNewProject().then((project: Project) => {
      history.push(`/projects/${project.id}`)
    })
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  return (
    <div className="page projects">
      <div className="navigation-bar">
        <span className="option left">
          <img src="icons/logo.svg" alt="Menu" /> Archimila
        </span>
        <button type="button" className="option right" onClick={toggleMenu}>
          Menu
          {!isMenuOpen && <MenuOutline />}
          {isMenuOpen && <XOutline />}
        </button>
        <h1>Projetos</h1>
      </div>
      <button
        type="button"
        className="floating primary"
        onClick={onClickNewProject}
      >
        <PlusCircleOutline />
        Novo
      </button>
      {isMenuOpen && <Menu />}
      <ul className="project-list">
        {projects.map((project: any) => (
          <li key={project.id}>
            <NavLink to={`/projects/${project.id}`}>
              <div className="chrome-bug">
                <span className="title">{project.title}</span>
                <CheveronRightOutline />
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default withRouter(
  connect(null, { onNewProject: actions.onNewProject })(Projects)
)
