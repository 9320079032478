import * as ActionTypes from './actionTypes'
import { NEW_PROJECT } from '../project/actionTypes'
import Properties from './Properties'
import { NEW_DRAWING } from '../drawing/actionTypes'

const initialState = {
  selected: undefined,
  1: {
    id: 1,
    title: 'Novo Projeto',
    notes: undefined,
  },
}

const update = (state: any, instance: Properties) => {
  const newState = { ...state }
  newState[instance.id] = instance
  return newState
}

const save = (state: any, properties: Properties, history: any) => {
  const newState = update(state, properties)
  history.goBack()
  return newState
}

const propertiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case NEW_DRAWING:
    case NEW_PROJECT:
      return update(state, action.payload.properties)
    // case ActionTypes.NEW_DRAWING:
    //     return update(state, action.payload.properties)
    case ActionTypes.SAVE_PROPERTIES:
      return save(state, action.payload.properties, action.payload.history)
    default:
      return state
  }
}

// const onNewProperties = (state, { properties }) => {
//   const newState = { ...state }
//   newState[properties.id] = { ...properties }
//   return newState
// }

export default propertiesReducer
