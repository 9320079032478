import * as Drawing from '../features/drawing'
import * as Project from '../features/project'
import * as Properties from '../features/properties'
import { saveToLocalStorage } from '../features/localStorage/localStorage';

const SAVING_TRIGGERING_ACTIONS = [
  Drawing.actionTypes.LINE_FORM_OK,
  Drawing.actionTypes.LINE_FORM_DELETE,
  Drawing.actionTypes.BLOCK_SAVE,
  Drawing.actionTypes.BLOCK_DELETE,
  Drawing.actionTypes.NEW_DRAWING,
  Drawing.actionTypes.DELETE_DRAWING,
  Drawing.actionTypes.UNDELETE_DRAWING,
  Project.actionTypes.NEW_PROJECT,
  Project.actionTypes.DELETE_PROJECT,
  Project.actionTypes.UNDELETE_PROJECT,
  Properties.actionTypes.SAVE_PROPERTIES,
]

const saveDelay = 1000
let lastTimestamp = 0
let timeout: any
const save = (state: any) => {
  clearTimeout(timeout)
  const now = new Date().getTime()
  if (now > lastTimestamp + saveDelay) {
    saveToLocalStorage(state)
    lastTimestamp = now
  } else {
    timeout = setTimeout(() => save(state), saveDelay)
  }
}
const localStorageMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action)
  if (SAVING_TRIGGERING_ACTIONS.indexOf(action.type) > -1) {
    save(store.getState())
  }
  return result
}
export default localStorageMiddleware
