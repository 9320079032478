import {
  ON_NEW_LINE,
  ON_SELECT_LINE,
  ON_SELECT_BLOCK,
  ON_SELECT_DIMENSION,
  ON_CANCEL,
  ON_ZOOM,
  ON_SET_TEMP,
} from './actionTypes'
import Line from '../drawing/Line'
import Block, { DrawingDimension } from '../drawing/Block'

export const onAddLine = (angle: number, lastLineSelected?: Line) => {
  return {
    type: ON_NEW_LINE,
    payload: { angle, lastLineSelected },
  }
}

export const onSelectLine = (line: Line) => {
  return {
    type: ON_SELECT_LINE,
    payload: { line },
  }
}

export const onSelectBlock = (block: Block) => {
  return {
    type: ON_SELECT_BLOCK,
    payload: { block },
  }
}

export const onSelectDimension = (dimension: DrawingDimension) => {
  return {
    type: ON_SELECT_DIMENSION,
    payload: { dimension },
  }
}

export const onCancel = () => {
  return {
    type: ON_CANCEL,
  }
}

export const onZoomIn = () => {
  return {
    type: ON_ZOOM,
    payload: {
      zoom: +1,
    },
  }
}

export const onZoomOut = () => {
  return {
    type: ON_ZOOM,
    payload: {
      zoom: -1,
    },
  }
}

export const setTempEntity = (entity: Line | Block | undefined) => {
  return {
    type: ON_SET_TEMP,
    payload: {
      entity,
    },
  }
}
