import { Dispatch } from 'react'
import { getGlobalIds } from '../ids'
import { NEW_PROJECT, DELETE_PROJECT, UNDELETE_PROJECT } from './actionTypes'
import Project from './Project'

export const onNewProject = (): Dispatch<Promise<Project>> => {
  const projectId = getGlobalIds().nextId()
  const propertiesId = getGlobalIds().nextId()
  const project = {
    id: projectId,
    propertiesId,
    drawingIds: [],
    title: 'Novo Projeto',
  }
  return (dispatch: any): Promise<Project> => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: NEW_PROJECT,
        payload: {
          project,
          properties: {
            id: propertiesId,
            title: 'Novo Projeto',
            notes: '',
          },
        },
      })
      resolve(project)
    })
  }
}

export const onDeleteProject = (project: Project) => {
  return (dispatch: any): Promise<Project> => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DELETE_PROJECT,
        payload: { project },
      })
      resolve()
    })
  }
}

export const onUndeleteProject = (project: Project) => {
  return (dispatch: any): Promise<Project> => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UNDELETE_PROJECT,
        payload: { project },
      })
      resolve(project)
    })
  }
}
