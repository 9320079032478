import React, { Fragment } from 'react'
import { useParams, NavLink, useLocation, withRouter } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'
import {
  PencilAltOutline,
  DocumentAddOutline,
  CheveronLeftOutline,
} from 'heroicons-react'
import { getProject } from '../features/project/selectors'
import { selectors as drawingSelectors, actions } from '../features/drawing'
import Svg from '../components/svg/Svg'
import Drawing from '../features/drawing/Drawing'
import { DrawingLine } from '../features/drawing/Line'

type DrawingOptionProps = {
  drawing: any
  text: string
}
const DrawingOption: React.FC<DrawingOptionProps> = ({
  drawing,
  text,
}: DrawingOptionProps) => {
  return (
    <Fragment>
      <Svg drawing={drawing} onLineClick={(line: DrawingLine) => {}} />
      <span className="drawing-label">{text}</span>
    </Fragment>
  )
}

const Project: React.FC = (props: any) => {
  const { id } = useParams()
  const project = useSelector(getProject)(id)
  const drawings = useSelector(drawingSelectors.listDrawings)(id) || []
  const location = useLocation()
  const { history, onNewDrawing } = props
  const onClickNewDrawing = () => {
    onNewDrawing(project).then((drawing: Drawing) =>
      history.push(`/projects/${id}/drawing/${drawing.id}`)
    )
  }

  return (
    <div className="page project">
      <div className="navigation-bar">
        <NavLink to="/projects" className="option left">
          <CheveronLeftOutline />
        </NavLink>
        <NavLink
          to={{
            pathname: `/projects/${id}/properties`,
            state: {
              background: location,
            },
          }}
          className="option right"
        >
          <PencilAltOutline />
        </NavLink>
        <h1>{project.title}</h1>
      </div>
      <div className="drawings-grid">
        {drawings.map((drawing: Drawing) => {
          return (
            <NavLink
              to={`/projects/${id}/drawing/${drawing.id}`}
              key={drawing.id}
              className="drawing-option"
            >
              <DrawingOption text={drawing.title || ''} drawing={drawing} />
            </NavLink>
          )
        })}
      </div>
      <button
        type="button"
        className="floating primary"
        onClick={onClickNewDrawing}
      >
        <DocumentAddOutline />
        Novo desenho
      </button>
    </div>
  )
}

export default withRouter(
  connect(null, { onNewDrawing: actions.onNewDrawing })(Project)
)
