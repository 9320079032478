import React, { Component } from 'react'
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { CheveronLeftOutline, KeyOutline } from 'heroicons-react'
import { actions } from '../features/login'
import { Login as ILogin } from '../features/login/types'

interface LoginProps extends RouteComponentProps {
  login: ILogin
  onLoginPost: (username?: string, password?: string) => void
}
type LoginState = {
  status: string
  login?: ILogin
}

class Login extends Component<LoginProps, LoginState> {
  usernameInput: React.RefObject<HTMLInputElement>

  passwordInput: React.RefObject<HTMLInputElement>

  constructor(props: LoginProps) {
    super(props)
    this.state = {
      status: '',
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.usernameInput = React.createRef<HTMLInputElement>()
    this.passwordInput = React.createRef<HTMLInputElement>()
  }

  static getDerivedStateFromProps(props: LoginProps, state: LoginState) {
    const loading = state.status === 'LOADING'
    if (!loading) {
      return {
        login: props.login,
      }
    }
    const loggedIn = !state.login?.loggedIn && props.login.loggedIn
    if (loggedIn) {
      props.history.push('/projects')
      return { status: '', login: props.login }
    }
    const loginError = !state.login?.error && props.login.error
    if (loginError) {
      return { status: '', login: props.login }
    }
    return null
  }

  componentDidMount() {
    this.usernameInput.current?.focus()
  }

  onSubmit(event: any) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      status: 'LOADING',
    })
    const username = this.usernameInput.current?.value
    const password = this.passwordInput.current?.value
    const { onLoginPost } = this.props
    onLoginPost(username, password)
  }

  render() {
    const { status } = this.state
    const { login } = this.props
    const { error } = login
    const loading = status === 'LOADING'
    return (
      <div className="page login">
        <div className="navigation-bar">
          {!loading && (
            <NavLink to="/projects" className="option left">
              <CheveronLeftOutline />
            </NavLink>
          )}
          <h1>Login</h1>
        </div>
        <form onSubmit={this.onSubmit} className="form">
          <label htmlFor="username" className="label">
            Usuário ou E-mail
          </label>
          <input
            id="username"
            className="input"
            name="username"
            disabled={loading}
            ref={this.usernameInput}
          />
          <label htmlFor="password" className="label">
            Senha
          </label>
          <input
            id="password"
            className="input"
            name="password"
            type="password"
            disabled={loading}
            ref={this.passwordInput}
          />
          {!loading && (
            <button type="submit">
              <KeyOutline /> Entrar
            </button>
          )}
        </form>
        <span className="status">
          {loading && <img src="imgs/loading.gif" alt="Aguarde" />}
          {!loading && <span className="error">{error}</span>}
        </span>
        <span className="help-link">
          <span>Ainda não é um usuário? </span>
          <a
            className="create-account"
            href="https://archimila.com.br/wp-login.php?action=register"
            rel="noopener noreferrer"
            target="_blank"
          >
            Cadastre-se
          </a>
        </span>
        <span className="help-link">
          <span>Perdeu a senha? </span>
          <a
            className="lost-password"
            href="https://archimila.com.br/wp-login.php?action=lostpassword"
            rel="noopener noreferrer"
            target="_blank"
          >
            Recuperar senha
          </a>
        </span>
      </div>
    )
  }
}

export default withRouter(
  connect(
    (state: any) => ({
      login: state.login,
    }),
    {
      onLoginPost: actions.onLoginPost,
    }
  )(Login)
)
