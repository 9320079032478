import React from 'react'
import { useSelector, connect } from 'react-redux'
import { UserAddOutline, LogoutOutline } from 'heroicons-react'
import { NavLink } from 'react-router-dom'
import { getLogin } from '../../features/login/selectors'
import { actions } from '../../features/login'

const LoggedUser = (props: any) => {
  const login = useSelector(getLogin)
  const { onLogout } = props
  return (
    <div className="logged-user">
      {login.loggedIn && (
        <>
          <a
            className="user"
            href={`https://archimila.com.br/foruns/users/${login.user.nicename}/`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              className="avatar"
              src={login.user.avatar}
              alt={login.user.username}
            />
            <div className="username">{login.user.username}</div>
            <span className="label">Ver Perfil</span>
          </a>
          <button type="button" className="logout" onClick={onLogout}>
            <LogoutOutline />
            Logout
          </button>
        </>
      )}
      {!login.loggedIn && (
        <>
          <NavLink className="user" to="/login">
            <div className="avatar not-logged-in" />
            <div className="username">Projetista Anônimo</div>
            <span className="label not-logged-in">Faça Login</span>
          </NavLink>
          <a
            className="create-account"
            href="https://archimila.com.br/wp-login.php?action=register"
            rel="noopener noreferrer"
            target="_blank"
          >
            <UserAddOutline /> Cadastre-se
          </a>
        </>
      )}
    </div>
  )
}

export default connect(null, { onLogout: actions.onLogout })(LoggedUser)
