import React from 'react'
import { NavLink, useParams, useLocation } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'
import {
  PencilAltOutline,
  ZoomInOutline,
  ZoomOutOutline,
  CheveronLeftOutline,
} from 'heroicons-react'
import { selectors as projectSelectors } from '../features/project'
import { selectors as propertiesSelectors } from '../features/properties'
import {
  selectors as drawingSelectors,
  actions as drawingActions,
} from '../features/drawing'
import {
  selectors,
  actions,
  STATE_ADD_LINE,
  STATE_EDIT_LINE,
  STATE_EDIT_BLOCK,
} from '../features/drawingPage'
import Svg from '../components/svg/Svg'
import Line, { DrawingLine } from '../features/drawing/Line'
import Block, { DrawingDimension } from '../features/drawing/Block'
import LineForm from '../components/LineForm'
import { lastLine as drawingLastLine } from '../features/drawing/Drawing'
import BlockForm from '../components/BlockForm'

type ArrowButtonProps = {
  pos: string
  onClick: (angle: number) => any
  angle: number
}
const ArrowButton: React.FC<ArrowButtonProps> = ({
  pos,
  onClick,
  angle,
}: ArrowButtonProps) => (
  <button
    type="button"
    className={`button ${pos}`}
    onClick={() => onClick(angle)}
    aria-label="Adiciona linha"
  />
)
const arrowButtons = [
  { pos: 'top left', angle: (5 * Math.PI) / 4 },
  { pos: 'top center', angle: (3 * Math.PI) / 2 },
  { pos: 'top right', angle: (7 * Math.PI) / 4 },
  { pos: 'middle left', angle: Math.PI },
  { pos: 'middle right', angle: 0 },
  { pos: 'bottom left', angle: (3 * Math.PI) / 4 },
  { pos: 'bottom center', angle: Math.PI / 2 },
  { pos: 'bottom right', angle: Math.PI / 4 },
]

const Drawing: React.FC = (props: any) => {
  const { id, drawingId } = useParams()
  const project = useSelector(projectSelectors.getProject)(id)
  const drawing = useSelector(drawingSelectors.getDrawing)(drawingId)
  const properties = useSelector(propertiesSelectors.getProperties)(
    drawing.propertiesId
  )
  const location = useLocation()
  const drawingPageState = useSelector(selectors.getState)
  const { lastLine, selection } = drawingPageState
  const lastLineSelected = lastLine || drawingLastLine(drawing)
  let selectedBlock: Block | undefined = undefined
  let selectedDimension: DrawingDimension | undefined = undefined
  let selectedBlockLine: Line | undefined = undefined
  if (selection && (selection as any).lineId) {
    selectedBlock = selection as Block
  }
  if (selection && (selection as any).block) {
    selectedDimension = selection as DrawingDimension
    selectedBlock = selectedDimension.block
  }
  if (selectedBlock) {
    const [first] = drawing.lines.filter(
      (l: Line) => l.id === selectedBlock?.lineId
    )
    selectedBlockLine = first
  }

  const {
    onAddLine,
    onSelectLine,
    onSelectBlock,
    onSelectDimension,
    onSaveBlock,
    onDeleteBlock,
    onZoomIn,
    onZoomOut,
  } = props
  const onSelectDrawingLine = (line: DrawingLine) => {
    onSelectLine(drawing.lines.filter((l: Line) => l.id === line.id)[0])
  }
  const onButtonNewLine = (angle: number) => {
    onAddLine(angle, lastLineSelected)
  }

  return (
    <div className="page drawing">
      <div className="navigation-bar">
        <NavLink to={`/projects/${id}`} className="option left">
          <CheveronLeftOutline />
        </NavLink>
        <NavLink
          to={{
            pathname: `/projects/${id}/drawing/${drawingId}/properties`,
            state: {
              background: location,
            },
          }}
          className="option right"
        >
          <PencilAltOutline />
        </NavLink>
        <span className="title-subtitle">
          <h1>
            {properties.title}
            <small>{project.title}</small>
          </h1>
        </span>
      </div>
      {drawingPageState.currentState === STATE_ADD_LINE && (
        <>
          <div className="arrow-buttons">
            {arrowButtons.map((arrowButton: any) => (
              <ArrowButton
                key={arrowButton.angle}
                pos={arrowButton.pos}
                onClick={onButtonNewLine}
                angle={arrowButton.angle}
              />
            ))}
          </div>
          <div className="floating-toolbar">
            <button type="button" className="floating" onClick={onZoomIn}>
              <ZoomInOutline />
              Zoom +
            </button>
            <button type="button" className="floating" onClick={onZoomOut}>
              <ZoomOutOutline />
              Zoom -
            </button>
          </div>
        </>
      )}
      {drawingPageState.currentState === STATE_EDIT_LINE && (
        <LineForm
          line={selection as Line}
          drawing={drawing}
          key={(selection as Line).id}
        />
      )}
      <div className={`svg-area zoom-${drawingPageState.zoom}`}>
        <Svg
          drawing={drawing}
          lastLineSelected={lastLineSelected}
          selection={selection}
          onLineClick={onSelectDrawingLine}
          onBlockClick={onSelectBlock}
          onDimensionClick={onSelectDimension}
          selectedDimension={selectedDimension}
          temporaryEntity={drawingPageState.temporaryEntity}
        />
      </div>
      {drawingPageState.currentState === STATE_EDIT_BLOCK &&
        selectedBlock &&
        selectedBlockLine && (
          <BlockForm
            block={selectedBlock}
            line={selectedBlockLine}
            selectedDimensionIndex={selectedDimension?.index}
            onSaveBlock={(block: Block) => onSaveBlock(drawingId, block)}
            onDeleteBlock={(block: Block) => onDeleteBlock(drawingId, block)}
          />
        )}
    </div>
  )
}

export default connect(null, {
  onAddLine: actions.onAddLine,
  onSelectLine: actions.onSelectLine,
  onSelectBlock: actions.onSelectBlock,
  onSelectDimension: actions.onSelectDimension,
  onSaveBlock: drawingActions.onSaveBlock,
  onDeleteBlock: drawingActions.onDeleteBlock,
  onZoomIn: actions.onZoomIn,
  onZoomOut: actions.onZoomOut,
})(Drawing)
