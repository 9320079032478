export const TABLES = `  0
SECTION
  2
TABLES
  0
TABLE
  2
LTYPE
  5
5
100
AcDbSymbolTable
 70
     6
  0
LTYPE
  5
14
100
AcDbSymbolTableRecord
100
AcDbLinetypeTableRecord
  2
ByBlock
 70
     0
  3

 72
    65
 73
     0
 40
0.0
  0
LTYPE
  5
15
100
AcDbSymbolTableRecord
100
AcDbLinetypeTableRecord
  2
ByLayer
 70
     0
  3

 72
    65
 73
     0
 40
0.0
  0
LTYPE
  5
16
100
AcDbSymbolTableRecord
100
AcDbLinetypeTableRecord
  2
Continuous
 70
     0
  3
Solid line
 72
    65
 73
     0
 40
0.0
  0
LTYPE
  5
22A
100
AcDbSymbolTableRecord
100
AcDbLinetypeTableRecord
  2
ACAD_ISO02W100
 70
     0
  3
ISO dash __ __ __ __ __ __ __ __ __ __ __ __ __
 72
    65
 73
     2
 40
15.0
 49
12.0
 49
-3.0
  0
LTYPE
  5
22B
100
AcDbSymbolTableRecord
100
AcDbLinetypeTableRecord
  2
DOT
 70
     0
  3
Dot . . . . . . . . . . . . . . . . . . . . . .
 72
    65
 73
     2
 40
6.349999999999999
 49
0.0
 49
-6.349999999999999
  0
LTYPE
  5
22C
100
AcDbSymbolTableRecord
100
AcDbLinetypeTableRecord
  2
DASHED
 70
     0
  3
Dashed __ __ __ __ __ __ __ __ __ __ __ __ __ _
 72
    65
 73
     2
 40
19.05
 49
12.7
 49
-6.349999999999999
  0
LTYPE
  5
22F
100
AcDbSymbolTableRecord
100
AcDbLinetypeTableRecord
  2
DASHDOTX2
 70
     0
  3
Dash dot (2x) ____  .  ____  .  ____  .  ___
 72
    65
 73
     4
 40
50.8
 49
25.4
 49
-12.7
 49
0.0
 49
-12.7
  0
ENDTAB
  0
TABLE
  2
LAYER
  5
2
100
AcDbSymbolTable
 70
     5
  0
LAYER
  5
10
100
AcDbSymbolTableRecord
100
AcDbLayerTableRecord
  2
0
 70
     0
 62
     7
  6
Continuous
  0
LAYER
  5
223
100
AcDbSymbolTableRecord
100
AcDbLayerTableRecord
  2
Tracejado
 70
     0
 62
     7
  6
DASHED
  0
LAYER
  5
226
100
AcDbSymbolTableRecord
100
AcDbLayerTableRecord
  2
Pontilhado
 70
     0
 62
     7
  6
DOT
  0
LAYER
  5
227
100
AcDbSymbolTableRecord
100
AcDbLayerTableRecord
  2
TracoPonto
 70
     0
 62
     7
  6
DASHDOTX2
  0
LAYER
  5
22E
100
AcDbSymbolTableRecord
100
AcDbLayerTableRecord
  2
Continuo
 70
     0
 62
     7
  6
Continuous
  0
ENDTAB
  0
ENDSEC`;
