import Project from './Project'

export const listProjects = (state: any): Project[] => {
  const projectsState = state.projects
  return Object.keys(projectsState)
    .filter((key: any) => key !== 'selected')
    .map((key: any) => projectsState[key])
    .filter((project: Project) => !project?.deleted)
}
export const listDeletedProjects = (state: any): Project[] => {
  const projectsState = state.projects
  return Object.keys(projectsState)
    .filter((key: any) => key !== 'selected')
    .map((key: any) => projectsState[key])
    .filter((project: Project) => project?.deleted)
}

export const getProject = (state: any) => (id: any): Project => {
  return state.projects[id]
}
