import Line from '../drawing/Line'
import Block, { DrawingDimension } from '../drawing/Block'

export const STATE_ADD_LINE = 'ADD_LINE'
export const STATE_EDIT_LINE = 'EDIT_LINE'
export const STATE_EDIT_BLOCK = 'EDIT_BLOCK'
export type stateType =
  | typeof STATE_ADD_LINE
  | typeof STATE_EDIT_LINE
  | typeof STATE_EDIT_BLOCK

export interface DrawingPageState {
  lastLine?: Line
  selection?: Line | Block | DrawingDimension
  currentState: stateType
  zoom: number
  temporaryEntity?: Line | Block
}
