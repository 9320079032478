import Properties from '../properties/Properties'

export const sendDrawingEmail = (
  projectTitle: string,
  dxfFile: string,
  properties: Properties
) => {
  return fetch('/drawing-email.php', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      projectTitle,
      title: properties.title,
      notes: properties.notes,
      dxfFile,
    }),
  }).then((response: Response) => response.json())
}
