import Block from '../drawing/Block'
import { DrawingLine } from '../drawing/Line'

export const getElectricLines = (block: Block, line: DrawingLine) => {
  const electric = block
  const angle = line.angle + Math.PI / 2 + Math.PI * electric.rot
  const a1 = {
    x: line.p1.x + electric.pos * Math.cos(line.angle),
    y: line.p1.y + electric.pos * Math.sin(line.angle),
  }
  const b1 = {
    x: a1.x + 0.08 * Math.cos(angle),
    y: a1.y + 0.08 * Math.sin(angle),
  }
  const b0 = {
    x: b1.x + 0.04 * Math.cos(angle - Math.PI / 2),
    y: b1.y + 0.04 * Math.sin(angle - Math.PI / 2),
  }
  const b2 = {
    x: b1.x + 0.04 * Math.cos(angle + Math.PI / 2),
    y: b1.y + 0.04 * Math.sin(angle + Math.PI / 2),
  }
  const c1 = {
    x: b1.x + 0.08 * Math.cos(angle),
    y: b1.y + 0.08 * Math.sin(angle),
  }
  return [
    { p1: a1, p2: b1 },
    { p1: b1, p2: b2 },
    { p1: b2, p2: c1 },
    { p1: c1, p2: b0 },
    { p1: b0, p2: b1 },
  ]
}
