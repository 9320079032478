import React from 'react'
import { CheveronUp, CheveronRight } from 'heroicons-react'

const ToolbarMenuOption = (props: any) => {
  const { icon, onClick, label, value } = props
  return (
    <>
      <button type="button" onClick={onClick} className="more">
        <CheveronUp />
      </button>
      <button type="button" onClick={onClick} className="icon">
        {icon}
      </button>
      <button type="button" onClick={onClick} className="label">
        {label}
      </button>
      <button type="button" onClick={onClick} className="value">
        {value}
      </button>
      <button type="button" onClick={onClick} className="select">
        <CheveronRight />
      </button>
    </>
  )
}

export default ToolbarMenuOption
