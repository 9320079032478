import Block from '../drawing/Block'
import { DrawingLine } from '../drawing/Line'

export const getDoorLines = (block: Block, line: DrawingLine) => {
  const p1 = {
    x: line.p1.x + block.pos * Math.cos(line.angle),
    y: line.p1.y + block.pos * Math.sin(line.angle),
  }
  const p12 = {
    x: p1.x + (block.width * Math.cos(line.angle)) / 20.0,
    y: p1.y + (block.width * Math.sin(line.angle)) / 20.0,
  }
  const p2 = {
    x: p1.x + block.width * Math.cos(line.angle),
    y: p1.y + block.width * Math.sin(line.angle),
  }
  const p22 = {
    x: p1.x + block.width * (19.0 / 20.0) * Math.cos(line.angle),
    y: p1.y + block.width * (19.0 / 20.0) * Math.sin(line.angle),
  }
  const p3 = {
    x: p2.x + block.width * Math.cos(line.angle + Math.PI / 2),
    y: p2.y + block.width * Math.sin(line.angle + Math.PI / 2),
  }
  const p32 = {
    x: p3.x + (block.width * Math.cos(line.angle + Math.PI)) / 20.0,
    y: p3.y + (block.width * Math.sin(line.angle + Math.PI)) / 20.0,
  }
  const p4 = {
    x: p3.x + block.width * Math.cos(line.angle + Math.PI),
    y: p3.y + block.width * Math.sin(line.angle + Math.PI),
  }
  const p42 = {
    x: p3.x + block.width * (19.0 / 20.0) * Math.cos(line.angle + Math.PI),
    y: p3.y + block.width * (19.0 / 20.0) * Math.sin(line.angle + Math.PI),
  }
  const p5 = {
    x: p2.x + block.width * Math.cos(line.angle - Math.PI / 2),
    y: p2.y + block.width * Math.sin(line.angle - Math.PI / 2),
  }
  const p52 = {
    x: p5.x + (block.width * Math.cos(line.angle - Math.PI)) / 20.0,
    y: p5.y + (block.width * Math.sin(line.angle - Math.PI)) / 20.0,
  }
  const p6 = {
    x: p5.x + block.width * Math.cos(line.angle - Math.PI),
    y: p5.y + block.width * Math.sin(line.angle - Math.PI),
  }
  const p62 = {
    x: p5.x + block.width * (19.0 / 20.0) * Math.cos(line.angle - Math.PI),
    y: p5.y + block.width * (19.0 / 20.0) * Math.sin(line.angle - Math.PI),
  }
  const p13 = {
    x: p1.x + (block.width * Math.cos(line.angle + Math.PI / 2)) / 20.0,
    y: p1.y + (block.width * Math.sin(line.angle + Math.PI / 2)) / 20.0,
  }
  const p14 = {
    x: p1.x + (block.width * Math.cos(line.angle - Math.PI / 2)) / 20.0,
    y: p1.y + (block.width * Math.sin(line.angle - Math.PI / 2)) / 20.0,
  }
  const p23 = {
    x: p2.x + (block.width * Math.cos(line.angle + Math.PI / 2)) / 20.0,
    y: p2.y + (block.width * Math.sin(line.angle + Math.PI / 2)) / 20.0,
  }
  const p24 = {
    x: p2.x + (block.width * Math.cos(line.angle - Math.PI / 2)) / 20.0,
    y: p2.y + (block.width * Math.sin(line.angle - Math.PI / 2)) / 20.0,
  }
  let doorLines = []
  switch (block.rot) {
    case 0:
      doorLines = [
        { p1: p2, p2: p3 },
        { p1: p3, p2: p32 },
        { p1: p32, p2: p22 },
        { p1: p22, p2 },
        { p1: p13, p2: p14 },
        { p1: p23, p2: p24 },
      ]
      break
    case 1:
      doorLines = [
        { p1: p62, p2: p6 },
        { p1: p6, p2: p1 },
        { p1, p2: p12 },
        { p1: p12, p2: p62 },
        { p1: p13, p2: p14 },
        { p1: p23, p2: p24 },
      ]
      break
    case 2:
      doorLines = [
        { p1: p42, p2: p4 },
        { p1: p4, p2: p1 },
        { p1, p2: p12 },
        { p1: p12, p2: p42 },
        { p1: p13, p2: p14 },
        { p1: p23, p2: p24 },
      ]
      break
    case 3:
    default:
      doorLines = [
        { p1: p2, p2: p5 },
        { p1: p5, p2: p52 },
        { p1: p52, p2: p22 },
        { p1: p22, p2 },
        { p1: p13, p2: p14 },
        { p1: p23, p2: p24 },
      ]
  }
  return doorLines
}
