import { reloadOnServiceWorkerUpdate } from './serviceWorkerUpdate'
import { addVisibilityChangeListener } from './visibilityChangeListener'
import * as serviceWorker from './serviceWorker'

export const startServiceWorker = () => {
  try {
    serviceWorker.register({
      onRegistration: (reg: any) => {
        addVisibilityChangeListener(() => reg.update())
        return reg
      },
    })
    reloadOnServiceWorkerUpdate()
  } catch (err) {
    console.log(err)
  }
}
