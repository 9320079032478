import Properties from './Properties'
import { SAVE_PROPERTIES } from './actionTypes'

export const onSaveProperties = (properties: Properties, history: any) => {
  return {
    type: SAVE_PROPERTIES,
    payload: {
      properties,
      history,
    },
  }
}
