import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import 'source-sans-pro/source-sans-pro.css'

import './index.scss'

import App from './App'
import { startServiceWorker } from './features/serviceWorker'
import { actions } from './features/login'
import { store, history } from './store'

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )
}

startServiceWorker()

actions.onSilentlyFetchUser()(store.dispatch)
render()
